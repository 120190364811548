import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getIsSideNavShowing } from '../../redux/reducers/userInterface';
import SideNavItem from '../SideNavItem';


class SideNav extends Component {

  render() {
    const {isSideNavShowing, className} = this.props;
    return <div id="nav-sidebar" className={classNames("z200", {"side-nav-hidden": !isSideNavShowing}, className)}>
      <SideNavItem text={"Devices"} to={"/controllers"}/>
      <SideNavItem text={"Programs"} to={"/programs"}/>
      <SideNavItem text={"Preferences"} to={"/preferences"} className="sm-only"/>
    </div>
  }

}

const mapStateToProps = (state) => ({
  isSideNavShowing: getIsSideNavShowing(state),
});

export default connect(
  mapStateToProps,
)(SideNav);
