import React from "react";
import classNames from "classnames";

const BigCard = (props) => {
  const {className} = props;

  return <div className={classNames("card-big", className)}>
    {props.children}
  </div>
};

export default BigCard;
