import { combineReducers } from 'redux'
import user from './user';
import controllers from './controllers';
import programs from './programs';
import authentication from './authentication';
import userInterface from './userInterface';

const rootReducer = combineReducers({
  userStore: user,
  controllerStore: controllers,
  programStore: programs,
  authenticationStore: authentication,
  interfaceStore: userInterface,
});

export default rootReducer;
