import React, {Component} from 'react';
import PropTypes from 'prop-types';


export default class ClickOutsideNotifier extends Component {

  static propTypes = {
    children: PropTypes.element.isRequired,
    onClickOutside: PropTypes.func
  };

  static defaultProps = {
    onClickOutside: () => {}
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(event) {
    // We check if the document contains the target to handle a race condition where clicking the target caused it to
    // be removed from the render tree immediately before this handler is called.
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && document.contains(event.target)) {
      this.props.onClickOutside();
    }
  }

  render() {
    return <div ref={this.wrapperRef}>{this.props.children}</div>;
  }
}
