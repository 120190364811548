import React, {Component} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getIsNotificationShowing, getNotificationCount, getNotificationMessage } from "../../redux/reducers/userInterface";
import { hideNotification } from "../../redux/actions/userInterface";


class Notification extends Component {

  static propTypes = {
    type: PropTypes.oneOf(["success", "warning", "error"]),
    message: PropTypes.string
  };

  static defaultProps = {
    type: "error",
  };


  render() {
    const {isNotificationShowing, notificationCount, type, message, hideNotification} = this.props;

    return <div className={classNames({"bg-red-1": type === "error", "fade-out": !isNotificationShowing, "hide": notificationCount === 0},
      "fixed flex justify t0 l0 r0 z400 p20 bg-red-1 clr-beige-2")}>
      <span>{message}</span>
      <span className="curs-pointer" onClick={hideNotification}>X</span>
    </div>
  }
}


const mapStateToProps = (state) => ({
  notificationCount: getNotificationCount(state),
  isNotificationShowing: getIsNotificationShowing(state),
  message: getNotificationMessage(state),
});

const mapDispatchToProps = (dispatch) => ({
  hideNotification: () => dispatch(hideNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
