import React, {Component} from "react";
import PropTypes from "prop-types";
import Button from "../Button";


const noop = () => void 0;


class TimelineZoom extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.any,
    })),
  };

  static defaultProps = {
    onChange: noop
  };

  constructor(props) {
    super(props);
    const {onChange, options} = this.props;

    this.selectNextOption = this.selectNextOption.bind(this);
    this.selectPreviousOption = this.selectPreviousOption.bind(this);

    this.state = {
      selectedIndex: 0,
    };
    onChange(options[0]);
  }

  selectNextOption() {
    const {options, onChange} = this.props,
      selectedIndex = Math.min(options.length - 1, this.state.selectedIndex + 1);
    this.setState({selectedIndex});
    onChange(options[selectedIndex]);
  }

  selectPreviousOption() {
    const {options, onChange} = this.props,
      selectedIndex = Math.max(0, this.state.selectedIndex - 1);
    this.setState({selectedIndex});
    onChange(options[selectedIndex]);
  }

  render() {
    const {options} = this.props,
      text = options[this.state.selectedIndex].text;

    return <div className="flex justify">
      <Button title="-" onClick={this.selectPreviousOption}/>
      {text}
      <Button title="+" onClick={this.selectNextOption}/>
    </div>
  }
}

export default TimelineZoom;
