import React, {Component} from "react";
import * as _ from "lodash";
import {Link} from "react-router-dom";
import ControllerTitle from "../components/ControllerTitle";
import OutletDetail from "../components/OutletDetail";
import NavButton from "../components/NavButton";
import Button from '../components/Button'
import ControllerStatusIndicator from "../components/ControllerStatusIndicator";
import { getController, getControlSchemes } from "../redux/reducers/controllers";
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";


class ControllerDetail extends Component {

  constructor(props) {
    super(props);
    this.fetchOutletControlSchemes = this.fetchOutletControlSchemes.bind(this);
  }

  componentDidMount() {
    const {controller, match: {params: {id: controllerId}}} = this.props;

    if (_.get(controller, "registeredAt")) {
      this.fetchOutletControlSchemes(controllerId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.get(prevProps.controller, "registeredAt") && _.get(this.props.controller, "registeredAt")) {
      // Controller has just registered
      this.fetchOutletControlSchemes(this.props.match.params.id);
    }
  }

  async fetchOutletControlSchemes(controllerId) {
    const {services: {automatorApiService, notificationService}} = this.props;

    try {
      await automatorApiService.fetchOutletControlSchemes(controllerId);
    } catch (err) {
      notificationService.error(err, "An error occurred retrieving the device settings");
    }
  }

  render() {
    const {history, controller, controlSchemes, match: {params: {id: controllerId}}} = this.props;

    if (!controller) {
      return <h2>Loading...</h2>;
    }

    let body = [],
      status = null;
    let controllerIsRegistered = false;

    if (controller.registeredAt !== null) {
      controllerIsRegistered = true;
    }

    if (!controllerIsRegistered) {
      // Controller has not registered yet.
      status = <h2><em>This controller has not connected to the network yet</em></h2>;
    }
    else if (!controlSchemes) {
      status = <h3>Loading controller data...</h3>;
    } else {
      body = _.sortBy(controller.outlets, _.property("internalId")).map((outlet) => {
          const controlSchemeForOutlet = controlSchemes[outlet.internalId];
          return <OutletDetail controllerId={controllerId} outlet={outlet} controlScheme={controlSchemeForOutlet} key={outlet.internalId}/>
        });
    }

    return <div>
      <h2 className="flex align-center">
        <ControllerStatusIndicator controllerId={controllerId}/>
        <ControllerTitle controller={controller} verbose={true} editable={true}/>
        <div className="fl1">{/*Spacer*/}</div>
        <Link to={"/controllers/" + controller.id + "/admin"} className="sm-hide">
          <Button colour="grey" title="Admin"/>
        </Link>
      </h2>
      {status}
      {body}
      <div className="flex justify mt30p">
        <NavButton title="< Back" onClick={history.goBack}/>
        <Link to={`/controllers/${controller.id}/telemetry`}>
          <Button colour="grey" title="Telemetry"/>
        </Link>
      </div>
      <div className="flex justify-end mt15p sm-only">
        <Link to={"/controllers/" + controller.id + "/admin"}>
          <Button colour="grey" title="Admin"/>
        </Link>
      </div>
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  controller: getController(state, parseInt(ownProps.match.params.id, 10)),
  controlSchemes: getControlSchemes(state, ownProps.match.params.id),
});

export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(withServices(ControllerDetail));

