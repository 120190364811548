import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames';


class Switch extends Component {
  static propTypes = {
    on: PropTypes.bool,
    onClick: PropTypes.func,
    enabled: PropTypes.bool,
    className: PropTypes.string,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
  };

  static defaultProps = {
    on: false,
    onClick: () => {},
    enabled: true,
    className: ''
  };

  handleClick = (e) => {
    e.preventDefault();
    if(this.props.enabled) {
      this.props.onClick(!this.props.on);
    }
  };

  render() {
    const {leftLabel, rightLabel, on} = this.props,
      leftElement = leftLabel ?
        <span className={classNames("label left font-norm", !on ? "clr-blue-4" : "clr-gray-65")}>{leftLabel}</span>
        : null,
      rightElement = rightLabel ?
        <span className={classNames("label right font-norm", on ? "clr-blue-4" : "clr-gray-65")}>{rightLabel}</span>
        : null;

    return <div className={classNames('switch', this.props.className)}>
      {leftElement}
      <div className={classNames('inner-container', {'on': on, 'disabled': !this.props.enabled})} onClick={this.handleClick}>
        <div className="toggle" children={this.props.children}></div>
      </div>
      {rightElement}
    </div>;
  }
}

export default Switch;
