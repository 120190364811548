import React, {Component} from "react";
import {Switch, Route, Link, Redirect} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import classnames from "classnames";
import logo from "../images/logo.svg";
import gradient from "../images/gradient.png";
import UserNavItem from "../components/UserNavItem";
import SideNav from "../components/SideNav";
import ControllerList from "../containers/ControllerList";
import ControllerDetail from "../containers/ControllerDetail";
import ControllerAdmin from "../containers/ControllerAdmin";
import ControllerTelemetry from "../containers/ControllerTelemetry";
import ProgramList from "../containers/ProgramList";
import ProgramDetail from "../containers/ProgramDetail";
import OutletSettings from "../containers/OutletSettings";
import Login from "../containers/Login";
import Preferences from "../containers/Preferences";
import NotFound from "./NotFound";
import HeaderNavItem from "./HeaderNavItem";
import HamburgerNavItem from "./HamburgerNavItem";
import Overlay from "./Overlay";
import Notification from "./Notification";

class App extends Component {

  render() {
    return (
      <div id="app" className={classnames("")}>

        <Notification/>

        <div id="header" className={classnames("z300")}>
          <Link to="/controllers" className={classnames("brand")}>
            <img src={logo} className={classnames("logo")} alt="logo"/>
            <div>AUTOMATOR</div>
          </Link>
          <img src={gradient} className={classnames("gradient")} alt=""/>
          <div className={classnames("nav")}></div>
          <HeaderNavItem>
            <UserNavItem/>
            <HamburgerNavItem/>
          </HeaderNavItem>
        </div>


        <div id="app-body" className={classnames("")}>

          <Overlay/>
          <Route path="/preferences" render={props => <SideNav className="sm-only" {...props}/>}/>
          <Route path="/controllers" component={SideNav}/>
          <Route path="/programs"    component={SideNav}/>

          <div id="main-content">
            <Switch>
              <Route exact path="/login" component={Login}/>
              <Route exact path="/" render={() => (<Redirect to="/controllers"/>)}/>
              <PrivateRoute exact path="/preferences" component={Preferences}/>
              <PrivateRoute exact path="/controllers" component={ControllerList}/>
              <PrivateRoute exact path="/controllers/:id" component={ControllerDetail}/>
              <PrivateRoute exact path="/controllers/:id/admin" component={ControllerAdmin}/>
              <PrivateRoute exact path="/controllers/:id/telemetry" component={ControllerTelemetry}/>
              <PrivateRoute exact path="/controllers/:controllerId/outlets/:outletInternalId" component={OutletSettings}/>
              <PrivateRoute exact path="/programs" component={ProgramList}/>
              <PrivateRoute exact path="/programs/:id" component={ProgramDetail}/>
              <Route path="*" component={NotFound}/>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
