import * as _ from "lodash";
import { SET_IS_AUTHENTICATED, SET_IS_INITIALIZED } from "../actions/authentication";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
};

const getLocalState = state => state.authenticationStore;

export default function authentication(state = initialState, action) {
  const nextState = _.cloneDeep(state);

  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      nextState.isAuthenticated = action.isAuthenticated;
      return nextState;
    case SET_IS_INITIALIZED:
      nextState.isInitialized= action.isInitialized;
      return nextState;
    default:
      return state;
  }
}

export function getIsAuthenticated(state) {
  return getLocalState(state).isAuthenticated;
}

export function getIsInitialized(state) {
  return getLocalState(state).isInitialized;
}
