import React, {Component} from "react";
import * as _ from "lodash"
import constants from "../../constants";
import * as d3 from "d3";

const margin = {top: 10, right: 10, bottom: 150, left: 60};

const colorMap = {
  [constants.OUTLET_STATE_ON_STRING]: "#54b227",
  [constants.OUTLET_STATE_OFF_STRING]: "#FFFFFF",
  [constants.OUTLET_STATE_UNKNOWN_STRING]: "rgba(213, 82,108,1)",
};

const colorScale = d3.scaleOrdinal()
  .domain(Object.keys(colorMap))
  .range(Object.values(colorMap));

// const t = d3.transition().duration(750);

function generateD3Data(data) {
  const d3data = {
    names: _.map(data, "label"),
    data: [],
  };

  for (const outletData of data) {
    d3data.data.push(...outletData.times.map(element => {
      return {
        ...element,
        label: outletData.label,
      };
    }));
  }

  return d3data;
}

function dateFormat(timestamp) {
  return `${(new Date(timestamp)).toDateString().match(/^\w+\s\w+\s\w+/)[0]} ${(new Date(timestamp)).toLocaleTimeString()}`;
}

function multilineDateFormat(timestamp) {
  return `${(new Date(timestamp)).toDateString().match(/^\w+\s\w+\s\w+/)[0]}\n${(new Date(timestamp)).toLocaleTimeString()}`;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function generateTimelineEventLabel(d) {
  return `State: ${capitalize(d.outletState)}\nFrom: ${dateFormat(d.starting_time)}\nTo: ${dateFormat(d.ending_time)}`
}

function wrap(d) {
  const textNode = d3.select(this);
  const lineHeight = 1.15; // ems
    const x = textNode.attr('x');
    const y = textNode.attr('y');
    const dy = parseFloat(textNode.attr('dy') || 0);
    const [firstLine, secondLine] = textNode.text().split(/\n/);
    textNode.text(null);
    textNode.append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em').text(firstLine);
    textNode.append('tspan').attr('x', x).attr('y', y).attr('dy', dy + lineHeight + 'em').text(secondLine);
}

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.updateChart = _.debounce(this.updateChart.bind(this), 200, {leading: true, trailing: true});
    this.getCurrentWidth = this.getCurrentWidth.bind(this);
    this.getCurrentHeight = this.getCurrentHeight.bind(this);
    this.setup = this.setup.bind(this);
    this.renderTimeline = this.updateChart;
    this.ref = React.createRef();
    this.setup();
  }

  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  shouldComponentUpdate(nextProps) {
    const {data: nextData} = nextProps,
      {data} = this.props;

    let shouldUpdate = false;

    if (data.length !== nextData.length) {
      shouldUpdate = true;
    } else {
      data.forEach((outletHistory, index) => {
        if (outletHistory.timeStamp !== nextData[index].timeStamp) {
          shouldUpdate = true;
        }
      })
    }
    return shouldUpdate;
  }

  getCurrentWidth() {
    const currentWidth = _.get(this.ref, "current.clientWidth", 600);
    return currentWidth;
  }

  getCurrentHeight() {
    const outletData = _.get(this.props, "data");

    if (outletData) {
      const numOutletsToDisplay = outletData.length;
      return numOutletsToDisplay * 50 + margin.top + margin.bottom;
    } else {
      return _.clamp(this.getCurrentWidth(), 300, 500);
    }
  }

  setup() {
    const d3data = generateD3Data(this.props.data);
    const width = this.getCurrentWidth();
    const height = this.getCurrentHeight();

    this.x = d3.scaleLinear()
      .domain([_.min(_.map(d3data.data, "starting_time")), _.max(_.map(d3data.data, "ending_time"))])
      .range([margin.left + 1, width - margin.right]);

    this.y = d3.scaleBand()
      .domain(d3data.names)
      .rangeRound([height - margin.bottom, margin.top])
      .paddingInner(0.1)
      .padding(0.1);

    this.yAxis = g => {
      const axis = g.call(d3.axisLeft(this.y).ticks(null, "s"));

      axis.selectAll(".tick text")
        .attr("font-size", 12);
    };

    this.xAxis = g => {
      const _width = this.getCurrentWidth();
      const plotWidth = _width - margin.left - margin.right;
      const pixelsBetweenXAxisTicks = 80;
      const numXAxisTicks = Math.trunc(plotWidth / pixelsBetweenXAxisTicks);
      const axis = g.call(d3.axisBottom(this.x).ticks(numXAxisTicks).tickFormat(multilineDateFormat));

      axis.selectAll(".tick text")
        .attr("font-size", 11)
        .attr("y", 15)
        .attr("x", 10)
        .attr("dy", ".35em")
        .each(wrap)
        .attr("transform", "rotate(45)")
        .style("text-anchor", "start");

      // axis.transition(t)
      //   .selectAll(".tick text")
      //   .on("start", wrap)
      //   .on("interrupt", wrap)
      //   .on("cancel", wrap)
    };

    this.legend = svg => {
      const g = svg
        .selectAll("g")
        .data(Object.keys(colorMap))
        .enter()
        .append("g");

      g.append("rect")
        .attr("x", -60)
        .attr("y", 2)
        .attr("width", 20)
        .attr("height", 20)
        .attr("fill", colorScale)
        .attr("stroke", "black");

      g.append("text")
        .attr("x", -35)
        .attr("y", 15)
        .attr("dx", "0.35em")
        .attr("fill", "#000000")
        .style("text-anchor", "start")
        .text(capitalize);
    };
  }

  updateChart(){
    console.time("d3 chart update");
    const d3data = generateD3Data(this.props.data);
    const width = this.getCurrentWidth();
    const height = this.getCurrentHeight();

    // Update our scales
    const minTime = _.min(_.map(d3data.data, "starting_time"));
    const maxTime = _.max(_.map(d3data.data, "ending_time"));
    this.x
      .domain([minTime, maxTime])
      .range([margin.left, width - margin.right]);
    this.y
      .domain(d3data.names)
      .rangeRound([height - margin.bottom, margin.top]);

    // Update our axes
    const timeline = d3.select(".timeline");
    timeline.selectAll(".y-axis")
      .call(this.yAxis);
    timeline.selectAll(".x-axis")
      .call(this.xAxis);

    // Update the graph plot
    const plot = timeline.selectAll(".plot");
    const rect = plot.selectAll("rect")
      .data(d3data.data);

    rect.exit()
      .remove();

    rect
      .attr("fill", d => colorScale(d.outletState))
      .attr("x", d => this.x(d.starting_time))
      .attr("y", d => this.y(d.label))
      .attr("width", d => this.x(d.ending_time) - this.x(d.starting_time))
      .attr("height", d => this.y.bandwidth());

    rect.selectAll("title").remove();
    rect.append("title")
      .text(generateTimelineEventLabel);

    // rect
    //   .transition()
    //     .ease(d3.easeLinear)
    //     .duration(500)
    //     .attr("x", d => this.x(d.starting_time))
    //     .attr("width", d => this.x(d.ending_time) - this.x(d.starting_time))
    //   .select("title")
    //     .text(d => `Outlet state: ${d.outletState}\nFrom: ${(new Date(d.starting_time)).toLocaleString()}\nTo: ${(new Date(d.ending_time)).toLocaleString()}`);

    rect.enter()
      .append("rect")
      .attr("fill", d => colorScale(d.outletState))
      .attr("y", d => this.y(d.label))
      .attr("x", d => this.x(d.starting_time))
      .attr("width", d => this.x(d.ending_time) - this.x(d.starting_time))
      .attr("height", d => this.y.bandwidth())
      .append("title")
      .text(generateTimelineEventLabel);

    // Update the drop shadow behind the plot
    const dropShadow = timeline.selectAll(".drop-shadow")
      .selectAll("rect")
      .data(d3data.names);

    dropShadow.exit().remove();

    dropShadow
      .attr("x", this.x(minTime))
      .attr("y", d => this.y(d))
      .attr("width", this.x(maxTime) - this.x(minTime))
      .attr("height", this.y.bandwidth());

    dropShadow.enter()
      .append("rect")
      .attr("fill", "#FFFFFF")
      .attr("opacity", 0.5)
      .attr("x", this.x(minTime))
      .attr("y", d => this.y(d))
      .attr("width", this.x(maxTime) - this.x(minTime))
      .attr("height", this.y.bandwidth())
      .attr("filter", "url(#f1)");

    // Update the legend
    const legend = timeline.selectAll(".legend");
    const legendWidth = Object.keys(colorMap).length * 80;
    legend.call(this.legend);
    legend.selectAll("g")
      .attr("transform", (d, i) => `translate(${(i + 1) * 80 + width / 2 - legendWidth / 2},${height - 40})`);

    console.timeEnd("d3 chart update");
  }

  render() {
    const height = this.getCurrentHeight();

    return <svg className="timeline w100" height={height} ref={this.ref}>
      <defs>
        <filter id="f1" x="0" y="0" width="200%" height="200%">
          <feOffset result="offOut" in="SourceAlpha" dx="2" dy="2" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <g className="drop-shadow"></g>
      <g className="plot"></g>
      <g className="x-axis" transform={`translate(0,${height - margin.bottom})`}></g>
      <g className="y-axis" transform={`translate(${margin.left},0)`}></g>
      <g className="legend" fontFamily="sans-serif" fontSize="10"
         textAnchor="end" >
      </g>
    </svg>
  }
}

export default Timeline;
