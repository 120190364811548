import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const noop = () => {};

export default function Button(props) {
  const {className, title, colour, disabled, onClick, ...rest } = props;

  return (
    <div className={classNames("button", {disabled}, className)} {...rest}>
      <div className={classNames("inner-container")}>
        <div className={classNames("graphic-container", {disabled}, colour)} onClick={disabled ? noop : onClick}>{title}</div>
      </div>
    </div>
  );
}

Button.propTypes = {
  title: PropTypes.string,
  colour: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  colour: "brown",
  disabled: false,
};
