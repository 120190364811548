import React, {useState} from "react";
import PropTypes from "prop-types";

function pad(value, digits){
  if (digits > 0) {
    return value.padStart(digits, "0");
  }
  return value;
}

function NumericInput(props) {
  const {min, max, digits} = props;
  const [displayValue, setDisplayValue] = useState(pad(props.value.toString(), digits));

  return <div className="relative">
    <input className={props.className} type="number" value={displayValue} onChange={(event) => {
      let newValue = Number(event.target.value);

      if (newValue < min) {
        newValue = min;
      } else if (newValue > max) {
        newValue = max;
      }

      setDisplayValue(newValue.toString());
      props.onChange(newValue);
    }} onFocus={(event) => {
      event.target.select();
    }} onBlur={() => {
      setDisplayValue(pad(displayValue, digits));
    }}/>
    <div className="absolute t0 l0 r0 clr-gray-50 text-center font-sm clickthrough">{props.label}</div>
  </div>
}


NumericInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  digits: PropTypes.number,
  label: PropTypes.string
};

NumericInput.defaultProps = {
  onChange: () => {},
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER
};


export default NumericInput;