import React from 'react';
import { connect } from 'react-redux'
import { toggleSideNavShowing } from '../../redux/actions/userInterface';
import hamburgerIcon from '../../images/hamburger.svg';

const HamburgerNavItem = ({toggleSideNavShowing}) => {
  return <div id="hamburger-nav-item" className="flex" onClick={toggleSideNavShowing}>
    <img src={hamburgerIcon} className="icon curs-pointer" alt="show or hide menu"/>
    </div>;
};


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  toggleSideNavShowing: () => dispatch(toggleSideNavShowing())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HamburgerNavItem);
