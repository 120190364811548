/**
 * Created by marcpeters on 8/16/17.
 */

import React, {Component} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import ControllerStatusIndicator from "../ControllerStatusIndicator";

export default class Controller extends Component {

  render() {
    const { className, controller } = this.props;
    const isPending = controller.registeredAt === null;
    let pendingClass = "";

    if (isPending) {
      pendingClass = "pending";
    }

    return (
      <Link to={"/controllers/" + controller.id} className={classNames("controller-stub", pendingClass, className)}>
        <ControllerStatusIndicator controllerId={String(controller.id)}/>
        <span className={classNames("name ml20p", {unnamed: !controller.friendlyName})}>{controller.friendlyName || "Unnamed"}</span>
      </Link>
    );
  }

}
