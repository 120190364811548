import React, {useEffect} from "react";

import Button from '../components/Button'
import BigCard from "../components/BigCard";
import {
  getSupportedTimezones,
  getTimezoneDropdownOptions,
  getSelectedTimezoneDropdownValue, getFormIsDirty
} from "../redux/reducers/user";
import { useSelector, useDispatch } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";
import DropDownList from "../components/DropDownList";
import { setFormValue, resetForm } from "../redux/actions/user";
import SaveCancelButtons from "../components/SaveCancelButtons";


async function load(props) {
  const { services: {automatorApiService, notificationService} } = props;

  try {
    await Promise.all([
      automatorApiService.fetchUserPreferences(),
      automatorApiService.fetchSupportedTimezones()
    ]);
  } catch (err) {
    notificationService.error(err, "An error occurred retrieving your preferences");
  }
}


async function save(props, timezone) {
  const { services: {automatorApiService, notificationService} } = props;

  try {
    await automatorApiService.patchUser(timezone);
  } catch (err) {
    notificationService.error(err, "An error occurred saving your preferences");
  }
}

async function logout(props) {
  const { services: {authenticationService} } = props;
  await authenticationService.logout()
}


function Preferences(props) {
  useEffect(() => { load(props) }, [props]);

  const supportedTimezones = useSelector(getSupportedTimezones);
  const timezoneDropdownOptions = useSelector(getTimezoneDropdownOptions);
  const selectedTimezone = useSelector(getSelectedTimezoneDropdownValue);
  const formIsDirty = useSelector(getFormIsDirty);
  const dispatch = useDispatch();

  if (!supportedTimezones) {
    return <h2>Loading...</h2>;
  }

  const title = (className) => <h1 className={className}>Preferences</h1>;
  const timezonePicker = <div className="flex align-center wrap">
    <span className="flex align-center mr10p" style={{minHeight: "40px"}}>Timezone:</span>
    <DropDownList options={timezoneDropdownOptions}
                  selectedValue={selectedTimezone}
                  onSelectedOptionChanged={(value) => dispatch(setFormValue("selectedTimezone", value))}/>
  </div>;
  const logoutControl = (className) => <div className={`flex flex-col mt15p ${className}`}>
    <div><em>You are currently logged in</em></div>
    <Button className="mt10p" colour='green' title='Logout' onClick={() => { logout(props) }}/>
  </div>;
  const saveCancelButtons = (className) =>
    <SaveCancelButtons className={`mt20p ${className}`}
                       onSave={() => save(props, selectedTimezone)}
                       onCancel={() => dispatch(resetForm())}/>;

  return (
    <div className="flex h100">
      <BigCard className="sm-hide">
        {title("text-center")}
        {timezonePicker}
        {formIsDirty && saveCancelButtons("justify-center")}
        {logoutControl("align-center")}
      </BigCard>
      <div className="sm-only">
        {title()}
        {timezonePicker}
        {formIsDirty && saveCancelButtons()}
        {logoutControl()}
      </div>
    </div>
  );
}


export default withServices(Preferences);
