import React, {Component} from "react";
import ProgramStub from "../components/ProgramStub";
import Button from '../components/Button'
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";

class ProgramList extends Component {

  constructor(props) {
    super(props);
    this.addProgram = this.addProgram.bind(this);
  }

  async componentDidMount() {
    const {automatorApiService, notificationService} = this.props.services;

    try {
      await automatorApiService.fetchPrograms();
    } catch (err) {
      notificationService.error(err, "An error occurred retrieving the list of programs");
    }
  }

  async addProgram() {
    const {automatorApiService, notificationService} = this.props.services;

    try {
      await automatorApiService.addProgram();
    } catch (err) {
      notificationService.error(err, "An error occurred adding the program");
    }
  }

  render() {
    const {programs} = this.props;

    if (!programs) {
      return <h2>Loading...</h2>;
    }

    return (
      <div className="program-list">
        {
          programs
            .sort((a, b) => a.id - b.id)
            .map((program) =>
              <ProgramStub program={program} key={program.id}/>,
            )
        }
        <Button className="mt20p" title="+ Add New" onClick={this.addProgram}/>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  programs: state.programStore.programs,
});

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withServices(ProgramList));
