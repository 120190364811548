import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from "react-router-dom";
import { toggleSideNavShowing } from "../../redux/actions/userInterface";
import classNames from "classnames";
import arrowIcon from "../../images/arrow.svg";

class SideNavItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCurrentRoute: props.history.location.pathname.startsWith(props.to)
    };

    this.stopListening = props.history.listen((location) => {
      if (location.pathname.startsWith(this.props.to)) {
        this.setState({isCurrentRoute: true});
      } else {
        this.setState({isCurrentRoute: false})
      }
    });
  }

  componentWillUnmount() {
    this.stopListening();
  }

  render() {
    const {text, to, toggleSideNavShowing, className} = this.props;

    return <Link to={to} onClick={toggleSideNavShowing}
                 className={classNames("nav-item", "flex", "trans-0-1", {"current": this.state.isCurrentRoute}, className)}>
      <span>{text}</span>
      <img src={arrowIcon} className={classNames("arrow-icon")} alt="right arrow"/>
    </Link>
  }

}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  toggleSideNavShowing: () => dispatch(toggleSideNavShowing()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SideNavItem));
