import React from "react";
import PropTypes from "prop-types";
import TimeIntervalInput from "../TimeIntervalInput";


export default function ScheduledModeForm(props) {
  return <div className="schedule-form mt30p">
    <div className="field">
      <span className="label mr20p">Start date:</span>
      <input type="text" value={props.startOn} onChange={props.onStartTimeChanged}/>
    </div>
    <div className="field">
      <span className="label mr20p">End date:</span>
      <input type="text" value={props.endOn} onChange={props.onEndTimeChanged}/>
    </div>
    <TimeIntervalInput label="ON for:" value={props.duration} onChange={props.onDurationChanged}/>
    <TimeIntervalInput label="Repeat every:" value={props.frequency} onChange={props.onFrequencyChanged}/>
  </div>;
}

ScheduledModeForm.propTypes = {
  startOn: PropTypes.string,
  endOn: PropTypes.string,
  frequency: PropTypes.object,
  duration: PropTypes.object,
  onStartTimeChanged: PropTypes.func,
  onEndTimeChanged: PropTypes.func,
  onDurationChanged: PropTypes.func,
  onFrequencyChanged: PropTypes.func
};
