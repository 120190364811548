
function getConfig() {
  switch((process.env.NODE_ENV || "").toLowerCase()) {
    case "production":
      return {
        API_HOST: "api.marcpeters.ca",
        API_PORT: 443,
        GC_API_URL: "https://api.marcpeters.ca",
        USE_SSL: true,
        HTTP_REQUEST_TIMEOUT_MS: 10000,
      };

    case "local":
    case "development":  //react-scripts forces NODE_ENV=development
      return {
        API_HOST: "localhost",
        API_PORT: 8000,
        GC_API_URL: "", // We rely on the create-react-app proxy server to forward our request.  See package.json
        USE_SSL: false,
        HTTP_REQUEST_TIMEOUT_MS: 10000,
      };

    default:
      throw new Error(`getConfig: unknown environment ${process.env.NODE_ENV}`);
  }
}


export { getConfig };
