/*
 * action types
 */

export const TOGGLE_SIDE_NAV_SHOWING = 'TOGGLE_SIDE_NAV_SHOWING';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';


/*
 * action creators
 */

export function toggleSideNavShowing() {
  return { type: TOGGLE_SIDE_NAV_SHOWING };
}

export function showNotification(message) {
  return { type: SHOW_NOTIFICATION, message };
}

export function hideNotification() {
  return { type: HIDE_NOTIFICATION };
}
