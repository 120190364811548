/*
 * action types
 */

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_IS_INITIALIZED= 'SET_IS_INITIALIZED';


/*
 * action creators
 */

export function setIsAuthenticated(isAuthenticated) {
  return { type: SET_IS_AUTHENTICATED, isAuthenticated };
}

export function setIsInitialized(isInitialized) {
  return { type: SET_IS_INITIALIZED, isInitialized };
}
