/*
 * action types
 */

export const SET_PROGRAMS = 'SET_PROGRAMS';
export const ADD_PROGRAM_INSTRUCTION = 'ADD_PROGRAM_INSTRUCTION';
export const REMOVE_PROGRAM_INSTRUCTION = 'REMOVE_PROGRAM_INSTRUCTION';
export const TOGGLE_PROGRAM_INSTRUCTION_OUTLET_STATE = 'TOGGLE_PROGRAM_INSTRUCTION_OUTLET_STATE';
export const SET_PROGRAM_INSTRUCTION_DURATION = 'SET_PROGRAM_INSTRUCTION_DURATION';


/*
 * action creators
 */

export function setPrograms(programs) {
  return { type: SET_PROGRAMS, programs };
}

export function addProgramInstruction(programId) {
  return { type: ADD_PROGRAM_INSTRUCTION, programId };
}

export function removeProgramInstruction(programId, index) {
  return { type: REMOVE_PROGRAM_INSTRUCTION, programId, index };
}

export function toggleProgramInstructionOutletState(programId, index) {
  return { type: TOGGLE_PROGRAM_INSTRUCTION_OUTLET_STATE, programId, index };
}

export function setProgramInstructionDuration(programId, index, duration) {
  return { type: SET_PROGRAM_INSTRUCTION_DURATION, programId, index, duration };
}
