import PropTypes from "prop-types";
import constants from "../../constants";
import nemaOutletIcon from "../../images/nema-outlet.svg";
import tapIcon from "../../images/tap.svg";
import React from "react";


export default function OutletIcon(props) {
  let outletIcon = null;

  switch(props.type) {
    case constants.OUTLET_TYPE_ELECTRIC:
      outletIcon = nemaOutletIcon;
      break;
    case constants.OUTLET_TYPE_HYDRAULIC:
      outletIcon = tapIcon;
      break;
    default:
      throw new Error(`Unknown outlet type`)
  }

  return <img className="h30p" src= {outletIcon} alt="Outlet type"/>
}

OutletIcon.propTypes = {
  type: PropTypes.string
};
