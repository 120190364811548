/**
 * Created by marcpeters on 8/20/17.
 */

import React, {Component} from "react";
import classnames from "classnames";
import EditableTextField from "../EditableTextField";
import { withServices } from "../../wrappers/ServicesWrapper";


class ProgramTitle extends Component {

  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
  }

  async onSave(newTitle) {
    const {program, services: {automatorApiService, notificationService}} = this.props;
    try {
      await automatorApiService.patchProgram(program.id, newTitle, program.instructions);
    } catch (err) {
      notificationService.error(err, "An error occurred editing the program name");
    }
  }

  render() {
    const {className, program} = this.props;

    return (
      <div className={classnames("ml10p", "w100", className)}>
        <EditableTextField size="large" text={program.friendlyName} onSave={this.onSave}/>
      </div>
    );
  }

}

export default withServices(ProgramTitle);
