import PropTypes from "prop-types";
import OutletIcon from "./OutletIcon";
import arrowIcon from "../../images/arrow.svg";
import classNames from "classnames";
import React from "react";


export default function OutletHeader(props) {
  const expanded = props.expanded;
  const collapsed = !props.expanded;

  return <div className="header flex align-center curs-pointer p10 trans-0-1" onClick={props.onClick}>
    <div className="flex align-center bg-gray-85 brd-rad-5 p5">
      <OutletIcon type={props.outlet.type}/>
      <span className="internal-name">{props.outlet.internalName}</span>
    </div>
    <span className="friendly-name ml15p">{props.outlet.friendlyName ? props.outlet.friendlyName : <em>unnamed </em>}</span>
    <div className="flex grow justify-end">
      <img src={arrowIcon} className={classNames("h30p", {"rotate-90": expanded})}
           alt={collapsed ? "Expand" : "Collapse"}/>
    </div>
  </div>
}


OutletHeader.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  outlet: PropTypes.object
};

