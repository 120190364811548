/**
 * Created by marcpeters on 8/20/17.
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class FlatButton extends Component {

  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const {className, onClick, children} = this.props;

    return (
      <div className={classNames("flat-button", "no-shrink", className)} onMouseDown={onClick}>
        {children}
      </div>
    );
  }
}

export default FlatButton;
