import Button from "../Button";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

SaveCancelButtons.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveDisabled: PropTypes.bool
};

SaveCancelButtons.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  saveDisabled: false
};


export default function SaveCancelButtons(props) {
  return <div className={classNames("flex", props.className)}>
    <Button colour="green" title="Save" disabled={props.saveDisabled} onClick={props.onSave}/>
    <Button colour="red" title="Cancel" className="ml15p" onClick={props.onCancel}/>
  </div>;
}
