/**
 * Created by marcpeters on 8/20/17.
 */

import React, {Component} from "react";
import _ from "lodash";
import classNames from "classnames";
import PropTypes from "prop-types";
import FlatButton from "../FlatButton";
import checkMarkIcon from "../../images/check-mark.svg";


const noop = () => void 0;


export default class EditableTextField extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    text: PropTypes.string,
    size: PropTypes.oneOf(["small", "large"]),
  };

  static defaultProps = {
    onSave: noop,
    onChange: noop,
    size: "small",
  };

  constructor(props) {
    super(props);
    this.handleFormDataChange = this.handleFormDataChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = {
      editing: false,
      dirtyValue: props.text || ""
    }
  }

  render() {
    const {className, text, size} = this.props,
      {editing, dirtyValue} = this.state,
      textToDisplay = _.isNil(text) ? "" : text,
      value = editing ? dirtyValue : textToDisplay;

    return <div className={classNames("editable-text-field", "flex", {focused: editing}, className)}>
      <input
        type="text"
        ref={c => this.input = c}
        className={classNames({"font-lg": size === "large"}, "w100", "ovf-ellipsis")}
        onChange={this.handleFormDataChange}
        onKeyUp={this.handleKeyUp}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={value}
        size={Math.max(value.length - 1, 15)}
      />
      {editing && <FlatButton className="ml10p" onClick={() => {this.props.onSave(dirtyValue)}}>
        <img src={checkMarkIcon} className="w100" alt="Save changes"/>
      </FlatButton>}
    </div>
  }

  onFocus() {
    this.setState({editing: true, dirtyValue: this.props.text || ""});
  }

  onBlur() {
    this.setState({editing: false});
  }

  handleKeyUp(event) {
    if (event.key === "Enter") {
      this.props.onSave(this.state.dirtyValue);
      this.input.blur()
    } else if (event.key === "Escape") {
      this.input.blur()
    }
  }

  handleFormDataChange(event) {
    const dirtyValue = event.target.value || "";
    this.setState({dirtyValue});
    this.props.onChange(dirtyValue)
  }
}
