import React, {Component} from "react";
import NavButton from "../components/NavButton";
import EditableTextField from '../components/EditableTextField';
import { getOutlet } from "../redux/reducers/controllers";
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";


class OutletSettings extends Component {

  constructor(props) {
    super(props);

    this.saveOutletFriendlyName = this.saveOutletFriendlyName.bind(this);
  }

  saveOutletFriendlyName() {
    const {services: {automatorApiService, notificationService}, match: {params: {controllerId, outletInternalId}}} = this.props;

    return async (friendlyName) => {
      try {
        await automatorApiService.patchOutlet(Number(controllerId), Number(outletInternalId), friendlyName);
      } catch (err) {
        notificationService.error(err, "An error occurred editing the outlet name");
      }
    };
  }

  render() {
    const {history, outlet} = this.props;

    if (!outlet) {
      return <h2>Loading...</h2>;
    }

    return <div>
      <EditableTextField text={outlet.friendlyName || "unnamed"} size="large"
                         onSave={this.saveOutletFriendlyName(outlet.internalId)}/>
      <div className="flex justify mt30p">
        <NavButton title="< Back" onClick={history.goBack}/>
      </div>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  outlet: getOutlet(state, Number(ownProps.match.params.controllerId), Number(ownProps.match.params.outletInternalId)),
});

export default connect(
  mapStateToProps,
)(withServices(OutletSettings));

