import * as _ from "lodash";
import {SET_SUPPORTED_TIMEZONES, SET_USER_PREFERENCES, SET_FORM_VALUE, RESET_FORM} from "../actions/user";

const initialState = {
  supportedTimezones: null,
  selectedTimezone: null,
  form: {
    selectedTimezone: null
  }
};

const getLocalState = state => state.userStore;

export default function user(state = initialState, action) {
  const nextState = _.cloneDeep(state);

  switch (action.type) {
    case SET_USER_PREFERENCES:
      nextState.selectedTimezone = action.preferences.timezone;
      if (nextState.form.selectedTimezone === null) {
        nextState.form.selectedTimezone = action.preferences.timezone;
      }
      return nextState;
    case SET_SUPPORTED_TIMEZONES:
      nextState.supportedTimezones = action.supportedTimezones;
      return nextState;
    case SET_FORM_VALUE:
      nextState.form[action.element] = action.value;
      return nextState;
    case RESET_FORM:
      nextState.form.selectedTimezone = state.selectedTimezone;
      return nextState;
    default:
      return state;
  }
}

export function getPreferences(state) {
  return getLocalState(state).preferences;
}

export function getSupportedTimezones(state) {
  return getLocalState(state).supportedTimezones;
}

export function getSelectedTimezone(state) {
  return getLocalState(state).selectedTimezone;
}

export function getFormValue(element, state) {
  return getLocalState(state).form[element];
}

export function getTimezoneDropdownOptions(state) {
  const supportedTimezones = getSupportedTimezones(state);
  return (supportedTimezones || []).map(timezone => ({display: timezone, value: timezone.toLowerCase()}));
}

export function getSelectedTimezoneDropdownValue(state) {
  return getFormValue("selectedTimezone", state);
}

export function getFormIsDirty(state) {
  const localState = getLocalState(state);
  return localState.selectedTimezone !== localState.form.selectedTimezone;
}
