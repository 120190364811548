import React, {Component} from "react";
import classNames from "classnames";


export default class TextField extends Component {

  render() {
    const {className, ...rest} = this.props;

    return <input className={classNames(className)} {...rest}/>
  }
}
