import * as _ from "lodash";
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import { getController, getOutletHistoryForController } from '../redux/reducers/controllers';
import Timeline from "../components/Timeline";
import NavButton from "../components/NavButton";
import TimelineZoom from "../components/TimelineZoom";
import { toMillis } from "../helpers/schedule";
import { withServices } from "../wrappers/ServicesWrapper";


class ControllerTelemetry extends Component {
  constructor(props) {
    super(props);
    this.fetchHistoryForAllOutlets = this.fetchHistoryForAllOutlets.bind(this);
    this.toTimelineFormat = this.toTimelineFormat.bind(this);
    this.onTimeIntervalChange = this.onTimeIntervalChange.bind(this);
    this.startPollingOutletHistory = this.startPollingOutletHistory.bind(this);
    this.stopPollingOutletHistory = this.stopPollingOutletHistory.bind(this);

    this.timer = null;
  }

  componentWillUnmount() {
    this.stopPollingOutletHistory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.controller && this.props.controller) {
      // Controller data has just been fetched
      this.fetchHistoryForAllOutlets();
    }

    const previousTimeInterval = _.get(prevState, "timeInterval"),
      timeInterval = _.get(this.state, "timeInterval");

    if (previousTimeInterval !== timeInterval) {
      const pollingInterval = _.clamp(timeInterval / 200, 1000, 30000);
      this.stopPollingOutletHistory();
      this.startPollingOutletHistory(pollingInterval);
      this.fetchHistoryForAllOutlets();
    }
  }

  startPollingOutletHistory(pollingInterval) {
    if (this.timer === null) {
      this.timer = setInterval(() => this.fetchHistoryForAllOutlets(), pollingInterval);
    }
  }

  stopPollingOutletHistory() {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  async fetchHistoryForAllOutlets() {
    const timeInterval = _.get(this.state, "timeInterval"),
      {controller, services: {automatorApiService, notificationService}} = this.props;

    if (!controller || !timeInterval) {
      return;
    }
    console.timeStamp(`Fetch outlet history: controller ${controller.id}`);
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - timeInterval);

    try {
      const outletInternalIds = controller.outlets.map(_.property("internalId"));
      await automatorApiService.getAllOutletHistoryForController(controller.id, outletInternalIds, startDate.toISOString(), endDate.toISOString());
    } catch (err) {
      notificationService.error(err, "An error occurred retrieving the outlet history");
    }
  }

  onTimeIntervalChange({value: timeInterval}) {
    this.setState({timeInterval});
  }

  toTimelineFormat({history: outletHistory = [], timeStamp}, friendlyName = "unnamed") {
    const times = [];
    for (let i = 0; i < outletHistory.length - 1; i++) {
      const starting_time = new Date(outletHistory[i].time).getTime(),
        ending_time = new Date(outletHistory[i + 1].time).getTime(),
        outletState = outletHistory[i].state;

      times.push({outletState, starting_time, ending_time});
    }

    return {
      label: friendlyName,
      timeStamp,
      times
    }
  }

  render() {
    const {controller, outletHistory, history} = this.props;
    const zoomOptions = [
      {text: "1 week", value: toMillis({days: 7})},
      {text: "1 day", value: toMillis({days: 1})},
      {text: "4 hours", value: toMillis({hours: 4})},
      {text: "1 hour", value: toMillis({hours: 1})},
      {text: "30 minutes", value: toMillis({minutes: 30})},
      {text: "10 minutes", value: toMillis({minutes: 10})},
      {text: "5 minutes", value: toMillis({minutes: 5})}
    ];
    let loadingIndicator = null;
    let timelineData = null;

    if (!controller || !outletHistory) {
      loadingIndicator = <div>Loading...</div>
    } else {
      timelineData = controller.outlets.map((outlet) => {
        const historyForOutlet = outletHistory.find(_.matches({outletInternalId: outlet.internalId}));
        if (!historyForOutlet) {
          return undefined;
        }

        return this.toTimelineFormat(historyForOutlet, outlet.friendlyName);
      }).filter(_.negate(_.isNil));

      timelineData = _.sortBy(timelineData, "label");
    }

    return <div>
      <h2>{_.get(controller, "friendlyName")}</h2>
      {loadingIndicator || <Fragment>
        <TimelineZoom options={zoomOptions} onChange={this.onTimeIntervalChange}/>
        <Timeline data={timelineData}/>
      </Fragment>}
      <div className="flex justify mt30p">
        <NavButton title="< Back" onClick={history.goBack}/>
      </div>
    </div>;
  }
}



const mapStateToProps = (state, ownProps) => ({
  controller: getController(state, parseInt(ownProps.match.params.id, 10)),
  outletHistory: getOutletHistoryForController(state, parseInt(ownProps.match.params.id, 10)),
});

export default connect(
  mapStateToProps,
)(withServices(ControllerTelemetry));
