import React, {Component} from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import highlighterStyle from 'react-syntax-highlighter/dist/esm/styles/hljs/atelier-dune-dark';
import NavButton from "../components/NavButton";
import SecretText from "../components/SecretText";
import Button from "../components/Button";
import { getController, getStatus } from "../redux/reducers/controllers";
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";


class ControllerAdmin extends Component {

  constructor(props) {
    super(props);

    this.onClickDelete = this.onClickDelete.bind(this);
  }

  async componentDidMount() {
    const {match: {params: {id: controllerId}}, services: {automatorApiService, notificationService}} = this.props;

    try {
      await automatorApiService.getControllerStatus(controllerId);
    } catch (err) {
      notificationService.error(err, "An error occurred retrieving the device status");
    }
  }

  async onClickDelete() {
    const {history, match: {params: {id: controllerId}}, services: {automatorApiService, notificationService}} = this.props;

    try {
      await automatorApiService.deleteController(controllerId);
      history.replace("/controllers");
    } catch (err) {
      notificationService.error(err, "An error occurred deleting the device");
    }
  }

  render() {
    const {controller, status, history} = this.props;

    if (!controller) {
      return <h2>Loading...</h2>;
    }

    let body = null;

    if (!status) {
      body = "Retrieving status...";
    } else if (status.unreachable) {
      body = "Controller is unreachable";
    } else {
      body = <SyntaxHighlighter language="json" style={highlighterStyle}
                                customStyle={{"borderRadius": "5px", "padding": "10px"}}>
          {JSON.stringify(status, null, 2)}
        </SyntaxHighlighter>;
    }

    return <div className="controller-admin">
      {body}
      <div className="AccessToken">
        <h3>Access token:</h3>
        <SecretText text={controller.accessToken}/>
      </div>

      <div className="footer">
        <NavButton title="< Back" onClick={history.goBack}/>
        <Button colour="red" title="Delete" onClick={this.onClickDelete}/>
      </div>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  controller: getController(state, parseInt(ownProps.match.params.id, 10)),
  status: getStatus(state, parseInt(ownProps.match.params.id, 10)),
});

export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(withServices(ControllerAdmin));
