import React from "react";
import store from "../redux/store";
import {getConfig} from "../config";
import NotificationService from "../services/NotificationService";
import AuthenticationService from "../services/AuthenticationService";
import AutomatorAPI from "../services/AutomatorAPI";
import { isUnauthorizedResponse } from "../helpers/httpRequest";

const config = getConfig();
const notificationService = new NotificationService({store});
const automatorApiService = new AutomatorAPI({config, store});
const authenticationService = new AuthenticationService({automatorApiService, notificationService, store});

automatorApiService.onRequestError((err) => {
  if (isUnauthorizedResponse(err)) {
    authenticationService.logout();
    notificationService.error(err, "You have been logged out");
  }
});

authenticationService.initialize();

const services = {
  notificationService,
  authenticationService,
  automatorApiService,
};

export function withServices(WrappedComponent) {
  return function (props) {
    return <WrappedComponent services={services} {...props}/>
  };
}
