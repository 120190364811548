import React, {Component} from "react";
import {sumIntervalObjects, toHumanFriendlyString} from "../helpers/schedule";
import NavButton from "../components/NavButton";
import Button from '../components/Button'
import ProgramTitle from "../components/ProgramTitle";
import { getProgram } from "../redux/reducers/programs";
import {
  addProgramInstruction,
  removeProgramInstruction,
  setProgramInstructionDuration,
  toggleProgramInstructionOutletState
} from "../redux/actions/programs";
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";
import Switch from "../components/Switch";
import constants from "../constants";
import TimeIntervalInput from "../components/TimeIntervalInput";


class ProgramDetail extends Component {

  constructor(props) {
    super(props);
    this.fetchPrograms = this.fetchPrograms.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const {program} = this.props;

    if (!program) {
      this.fetchPrograms();
    }
  }

  async fetchPrograms() {
    const {services: {automatorApiService, notificationService}} = this.props;

    try {
      await automatorApiService.fetchPrograms();
    } catch (err) {
      notificationService.error(err, "An error occurred retrieving the program");
    }
  }


  async onClickDelete() {
    const {history, match: {params: {id: programId}}, services: {automatorApiService, notificationService}} = this.props;

    try {
      await automatorApiService.deleteProgram(programId);
      history.replace("/programs");
    } catch (err) {
      notificationService.error(err, "An error occurred deleting the program");
    }
  }


  async onClickSave() {
    const {history, program, match: {params: {id: programId}}, services: {automatorApiService, notificationService}} = this.props;

    try {
      await automatorApiService.patchProgram(programId, program.friendlyName, program.instructions);
      history.replace("/programs");
    } catch (err) {
      notificationService.error(err, "An error occurred saving the program");
    }
  }


  render() {
    const {history, program, addInstruction, removeInstruction, toggleInstructionOutletState, setInstructionDuration} = this.props;

    if (!program) {
      return <h2>Loading...</h2>;
    }

    const body = program.instructions.map((instruction, index) => {
      const timeOffsetLabel = index === 0 ? null :
        <div className="absolute pl5 pr7 pb2 bg-white brd-1 brd-gray-45 font-pixelated italic clr-gray-40"
             style={{top: "-1px", left: "-1px", borderRadius: "0 0 4px"}}>
          {`+${toHumanFriendlyString(sumIntervalObjects(program.instructions.slice(0, index)))}`}
        </div>;

      return <div className="p10 flex justify relative brd-gray-45 brd-bot-1"
                  key={index}>
        {timeOffsetLabel}
        <Switch leftLabel="OFF" rightLabel="ON"
          on={instruction.outletState === constants.OUTLET_STATE_ON_STRING}
          onClick={toggleInstructionOutletState(index)}/>
          <TimeIntervalInput value={instruction.duration} onChange={setInstructionDuration(index)}/>
        <div className="flex align-center">
          <div className="clr-red-1 clr-white-hov bg-red-1-hov brd-1 brd-red-1 brd-rad-3 curs-pointer font-lg px10"
               onClick={removeInstruction(index)}>
            X
          </div>
        </div>
      </div>;
    });

    return <div>
      <h2 className="flex align-center">
        <ProgramTitle program={program} editable={true}/>
        <div className="fl1">{/*Spacer*/}</div>
      </h2>
      <div className="brd-1 brd-rad-3 brd-gray-45 bx-shdw-lg-soft-0">
        {body}
      </div>
      <Button className="mt20p" title="+ New Instruction" onClick={addInstruction}/>
      <div className="flex justify mt30p">
        <NavButton title="< Back" onClick={history.goBack}/>
        <div className="flex">
          <Button colour="green" title="Save" className="mr15p" onClick={this.onClickSave}/>
          <Button colour="red" title="Delete" onClick={this.onClickDelete}/>
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state, ownProps) => ({
  program: getProgram(state, parseInt(ownProps.match.params.id, 10)),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addInstruction: () => dispatch(addProgramInstruction(ownProps.match.params.id)),
  removeInstruction: (index) => () => dispatch(removeProgramInstruction(ownProps.match.params.id, index)),
  toggleInstructionOutletState: (index) => () => dispatch(toggleProgramInstructionOutletState(ownProps.match.params.id, index)),
  setInstructionDuration: (index) => (duration) => dispatch(setProgramInstructionDuration(ownProps.match.params.id, index, duration))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withServices(ProgramDetail));

