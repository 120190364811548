import {
  createStore,
  compose,
  // applyMiddleware
} from 'redux';
import rootReducer from './reducers';
import constants from '../constants';


const initialState = {};
const enhancers = [];
// const middleware = [
//   thunk,
//   routerMiddleware(history)
// ]

if (process.env.NODE_ENV === constants.NODE_ENV_LOCAL) {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  // applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store;
