import React from 'react';
import { connect } from 'react-redux'

const HeaderNavItem = ({children}) => {
  return <div id="header-nav-item">
    {children}
  </div>
};

const mapStateToProps = (state) => ({
});

export default connect(
  mapStateToProps,
)(HeaderNavItem);
