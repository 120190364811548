import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import {withServices} from "../../wrappers/ServicesWrapper";
import * as _ from "lodash";
import DropDownList from "../DropDownList";
import PropTypes from "prop-types";
import {getPrograms} from "../../redux/reducers/programs";
import DateTimePicker from "../DateTimePicker";


async function load(props) {
  const {services: {automatorApiService, notificationService}} = props;

  try {
    await automatorApiService.fetchPrograms();
  } catch (err) {
    notificationService.error(err, "An error occurred retrieving the list of programs");
  }
}


function ProgrammedModeForm(props) {
  useEffect(() => { load(props) }, [props]);

  const {programId, onProgramIdChanged} = props;
  const programs = useSelector(getPrograms);

  if (programs === null) {
    return <div>Loading programs...</div>
  } else if (programs.length === 0) {
    return <div>No programs have been created yet</div>
  }

  const programDropdownOptions = programs.map(program => ({display: program.friendlyName, value: program.id}));
  const selectedDropdownIndex = programDropdownOptions.findIndex(_.matches({value: programId}));

  return <div className="mt30p">
    <div className="flex align-center justify-start sm-wrap">
      <span className="sm-hide no-shrink text-right w120p mr20p font-norm">Program:</span>
      <span className="sm-only w100 mb5p font-norm">Program:</span>
      <DropDownList
        options={programDropdownOptions}
        selectedIndex={selectedDropdownIndex}
        onSelectedOptionChanged={onProgramIdChanged}/>
    </div>
    <div className="mt10p flex align-center justify-start sm-wrap">
      <span className="sm-hide no-shrink text-right w120p mr20p font-norm">Start date:</span>
      <span className="sm-only w100 mt10p mb5p font-norm">Start date:</span>
      <DateTimePicker value={props.startOn} onChange={props.onStartTimeChanged}/>
    </div>
    <div className="mt10p flex align-center justify-start sm-wrap">
      <span className="sm-hide no-shrink text-right w120p mr20p font-norm">End date:</span>
      <span className="sm-only w100 mt10p mb5p font-norm">End date:</span>
      <DateTimePicker value={props.endOn} onChange={props.onEndTimeChanged}/>
    </div>
  </div>;
}


ProgrammedModeForm.propTypes = {
  programId: PropTypes.number,
  startOn: PropTypes.object.isRequired,
  endOn: PropTypes.object.isRequired,
  onStartTimeChanged: PropTypes.func,
  onEndTimeChanged: PropTypes.func,
  onProgramIdChanged: PropTypes.func.isRequired
};


export default withServices(ProgrammedModeForm);