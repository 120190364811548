import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import store from './redux/store';
import {unregister} from "./registerServiceWorker";

import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';

import "./styles/css/index.css";
import App from "./components/App";

SyntaxHighlighter.registerLanguage("json", json);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App/>
    </Provider>
  </BrowserRouter>, document.getElementById("root"));

unregister();
