import {showNotification, hideNotification} from "../redux/actions/userInterface";
import constants from "../constants";


class NotificationService {

  constructor({store}) {
    this._store = store;
    this._clearNotificationTimer = null;
  }

  async error(error, message) {
    if (!error.notified) {
      clearInterval(this._clearNotificationTimer);
      this._store.dispatch(showNotification(message));
      this._clearNotificationTimer = setTimeout(() => {
        this._store.dispatch(hideNotification());
      }, constants.NOTIFICATION_AUTOHIDE_DELAY);
    }
    error.notified = true;
  }
}

export default NotificationService;
