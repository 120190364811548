//TODO: Functions in this file should probably belong to a common library shared with the back-end
import * as _ from "lodash";
import constants from "../constants";

export function toIntervalString(interval) {
  return `${interval.days ? interval.days + "D" : ""}` +
    `${interval.hours ? interval.hours + "H" : ""}` +
    `${interval.minutes ? interval.minutes + "M" : ""}` +
    `${interval.seconds ? interval.seconds + "S" : ""}`;
}


export function toIntervalObject(intervalString) {
  const days = _.get(intervalString.match(/(\d+)D/), "1", 0);
  const hours = _.get(intervalString.match(/(\d+)H/), "1", 0);
  const minutes = _.get(intervalString.match(/(\d+)M/), "1", 0);
  const seconds = _.get(intervalString.match(/(\d+)S/), "1", 0);

  return {days, hours, minutes, seconds};
}


export function sumIntervalObjects(intervalObjects) {
  let days = _.sum(_.compact(intervalObjects.map(_.property("duration.days"))).map(Number));
  let hours = _.sum(_.compact(intervalObjects.map(_.property("duration.hours"))).map(Number));
  let minutes = _.sum(_.compact(intervalObjects.map(_.property("duration.minutes"))).map(Number));
  let seconds = _.sum(_.compact(intervalObjects.map(_.property("duration.seconds"))).map(Number));

  minutes += Math.trunc(seconds / 60);
  seconds %= 60;
  hours += Math.trunc(minutes / 60);
  minutes %= 60;
  days += Math.trunc(hours / 24);
  hours %= 24;

  return {days, hours, minutes, seconds};
}


export function toHumanFriendlyString(intervalObject) {
  const stringComponents = [];

  if (intervalObject.days > 0) {
    stringComponents.push(`${intervalObject.days} day${intervalObject.days > 1 ? "s" : ""}`);
  }
  if (intervalObject.hours > 0) {
    stringComponents.push(`${intervalObject.hours} hour${intervalObject.hours > 1 ? "s" : ""}`);
  }
  if (intervalObject.minutes > 0) {
    stringComponents.push(`${intervalObject.minutes} minute${intervalObject.minutes > 1 ? "s" : ""}`);
  }
  if (intervalObject.seconds > 0) {
    stringComponents.push(`${intervalObject.seconds} second${intervalObject.seconds > 1 ? "s" : ""}`);
  }

  if (stringComponents.length > 0) {
    return stringComponents.join(", ")
  } else {
    return "0 seconds";
  }
}


export function toMillis(interval) {
  let {days = 0, hours = 0, minutes = 0, seconds = 0} = interval;

  return days * constants.MS_PER_DAY +
    hours * constants.MS_PER_HOUR +
    minutes * constants.MS_PER_MIN +
    seconds * constants.MS_PER_SEC;
}
