import React, { Component, Fragment } from "react";
import {Redirect} from 'react-router-dom';

import Button from '../components/Button'
import TextField from '../components/TextField'
import BigCard from "../components/BigCard";
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";

class Login extends Component {

  constructor(props) {
    super(props);
    this.onFormDataChanged = this.onFormDataChanged.bind(this);
    this.login = this.login.bind(this);
  }

  state = {
    redirectToReferrer: false,
    username: "",
    password: ""
  };

  async login() {
    const {authenticationService} = this.props.services;
    await authenticationService.login(this.state.username, this.state.password);
    this.setState(() => ({redirectToReferrer: true}));
  }

  onFormDataChanged(fieldName) {
    return (event) => {
      const newValue = event.target.value;
      return this.setState(() => ({[fieldName]: newValue}));
    };
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, username, password } = this.state;
    if (redirectToReferrer === true) {
      return <Redirect to={from}/>
    }

    const loginForm = <Fragment>
      <div className={"row"}>
        <label>Username</label>
        <TextField
          type="text"
          name="username"
          value={username}
          onChange={this.onFormDataChanged("username")}
        />
      </div>
      <div className={"row"}>
        <label>Password</label>
        <TextField
          type="password"
          name="password"
          value={password}
          onChange={this.onFormDataChanged("password")}
        />
      </div>
      <Button className="login-button" colour='green' title='Login' onClick={this.login}/>
    </Fragment>;

    return (
      <div className="flex-container">
        <BigCard className={"login"}>
          <h1>
            Login
          </h1>
          {loginForm}
        </BigCard>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
});

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withServices(Login));
