import React from "react";
import PropTypes from "prop-types";
import Switch from "../Switch";
import constants from "../../constants";

export default function ConstantModeForm(props) {
  return <Switch leftLabel="OFF" rightLabel="ON"
            on={props.value === constants.OUTLET_STATE_ON_STRING}
            onClick={props.onClick}/>;
}

ConstantModeForm.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};
