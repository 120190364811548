import * as _ from "lodash";
import { SET_PROGRAMS, ADD_PROGRAM_INSTRUCTION, REMOVE_PROGRAM_INSTRUCTION, TOGGLE_PROGRAM_INSTRUCTION_OUTLET_STATE,
  SET_PROGRAM_INSTRUCTION_DURATION } from "../actions/programs";
import {toIntervalObject} from "../../helpers/schedule";
import constants from "../../constants";

const initialState = {
  programs: null,
};

const getLocalState = state => state.programStore;

export default function programs(state = initialState, action) {
  const nextState = _.cloneDeep(state);
  let program = null;
  let instruction = null;

  switch (action.type) {
    case SET_PROGRAMS:
      function deserializeProgram(program) {
        const instructions = JSON.parse(program.instructions).map(instruction => {
          return {
            ...instruction,
            duration: toIntervalObject(instruction.duration)
          };
        });
        return {...program, instructions};
      }
      nextState.programs = action.programs.map(deserializeProgram);
      return nextState;
    case ADD_PROGRAM_INSTRUCTION:
      program = nextState.programs.find(_.matches({id: Number(action.programId)}));
      const previousInstruction = _.last(program.instructions);
      const previousOutletState = _.get(previousInstruction, "outletState", constants.OUTLET_STATE_OFF_STRING);
      const outletState = oppositeOutletState(previousOutletState);
      program.instructions.push({outletState, duration: {seconds: 0}});
      return nextState;
    case REMOVE_PROGRAM_INSTRUCTION:
      program = nextState.programs.find(_.matches({id: Number(action.programId)}));
      _.remove(program.instructions, (val, index) => index === action.index);
      return nextState;
    case TOGGLE_PROGRAM_INSTRUCTION_OUTLET_STATE:
      program = nextState.programs.find(_.matches({id: Number(action.programId)}));
      instruction = program.instructions[action.index];
      instruction.outletState = oppositeOutletState(instruction.outletState);
      return nextState;
    case SET_PROGRAM_INSTRUCTION_DURATION:
      program = nextState.programs.find(_.matches({id: Number(action.programId)}));
      instruction = program.instructions[action.index];
      instruction.duration = action.duration;
      return nextState;
    default:
      return state;
  }
}

function oppositeOutletState(outletState) {
  return outletState === constants.OUTLET_STATE_OFF_STRING ? constants.OUTLET_STATE_ON_STRING : constants.OUTLET_STATE_OFF_STRING;
}

export function getProgram(state, programId) {
  return getLocalState(state).programs &&
    getLocalState(state).programs.find(program => program.id === Number(programId));
}

export function getPrograms(state) {
  return getLocalState(state).programs;
}
