import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import constants from "../../constants";
import DropDownList from "../DropDownList";


export default function ControlSchemeDropdown(props) {
  const controlSchemeDropdownOptions = [
    {display: "On / Off", value: constants.CONTROL_SCHEME_TYPE_CONSTANT},
    {display: "Scheduled", value: constants.CONTROL_SCHEME_TYPE_SCHEDULED},
    {display: "Programmed", value: constants.CONTROL_SCHEME_TYPE_PIANO_ROLL}
  ];
  const selectedControlSchemeDropdownIndex = controlSchemeDropdownOptions.findIndex(_.matches({value: props.selectedValue}));

  return <DropDownList options={controlSchemeDropdownOptions}
           selectedIndex={selectedControlSchemeDropdownIndex}
           onSelectedOptionChanged={props.onSelectedOptionChanged}/>
}


ControlSchemeDropdown.propTypes = {
  selectedValue: PropTypes.string,
  onSelectedOptionChanged: PropTypes.func
};
