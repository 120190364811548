import * as _ from "lodash";
import {WebsocketClient} from "websocket-transport";


export function isUnauthorizedResponse(errorOrResponse) {
  const statusCode = errorOrResponse.status || errorOrResponse.statusCode;
  const errorCode = _.get(errorOrResponse, "payload.code");

  if (statusCode === 401 ||
    (statusCode === 400 && errorCode === WebsocketClient.constants.ERROR_CODES.INVALID_CREDENTIALS)) {
    return true;
  }
  return false;
}

export function websocketNeedsReauthentication(websocketResponse) {
  return _.get(websocketResponse, "payload.code") === WebsocketClient.constants.ERROR_CODES.AUTHENTICATE_FIRST;
}
