import React, {Component} from "react";
import ControllerStub from "../components/ControllerStub";
import Button from '../components/Button'
import { connect } from "react-redux";
import { withServices } from "../wrappers/ServicesWrapper";

class ControllerList extends Component {

  constructor(props) {
    super(props);
    this.addController = this.addController.bind(this);
  }

  async componentDidMount() {
    const {automatorApiService, notificationService} = this.props.services;

    try {
      await automatorApiService.fetchControllers();
    } catch (err) {
      notificationService.error(err, "An error occurred retrieving the list of devices");
    }
  }

  async addController() {
    const {automatorApiService, notificationService} = this.props.services;

    try {
      await automatorApiService.addController();
    } catch (err) {
      notificationService.error(err, "An error occurred adding the device");
    }
  }

  render() {
    const {controllers} = this.props;

    if (!controllers) {
      return <h2>Loading...</h2>;
    }

    return (
      <div className="controller-list">
        {
          controllers
            .sort((a, b) => a.id - b.id)
            .map((controller) =>
              <ControllerStub controller={controller} key={controller.id}/>,
            )
        }
        <Button className="mt20p" title="+ Add New" onClick={this.addController}/>
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  controllers: state.controllerStore.controllers,
});

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(withServices(ControllerList));
