import * as _ from "lodash";
import {TOGGLE_SIDE_NAV_SHOWING, SHOW_NOTIFICATION, HIDE_NOTIFICATION} from "../actions/userInterface";

const initialState = {
  isSideNavShowing: false,
  notificationCount: 0,
  isNotificationShowing: false,
  notificationMessage: "",
};

const getLocalState = state => state.interfaceStore;

export default function userInterface(state = initialState, action) {
  const nextState = _.cloneDeep(state);

  switch (action.type) {
    case TOGGLE_SIDE_NAV_SHOWING:
      nextState.isSideNavShowing = !state.isSideNavShowing;
      return nextState;
    case SHOW_NOTIFICATION:
      nextState.isNotificationShowing = true;
      nextState.notificationCount++;
      nextState.notificationMessage = action.message;
      return nextState;
    case HIDE_NOTIFICATION:
      nextState.isNotificationShowing = false;
      return nextState;
    default:
      return state;
  }
}

export function getIsSideNavShowing(state) {
  return getLocalState(state).isSideNavShowing;
}

export function getNotificationCount(state) {
  return getLocalState(state).notificationCount;
}

export function getIsNotificationShowing(state) {
  return getLocalState(state).isNotificationShowing;
}

export function getNotificationMessage(state) {
  return getLocalState(state).notificationMessage;
}
