const control_scheme_type_constant = 'constant',
  control_scheme_type_scheduled = 'scheduled',
  control_scheme_type_piano_roll = 'programmed',
  control_scheme_type_sensor = 'sensor',
  outlet_state_on_string = 'on',
  outlet_state_off_string = 'off',
  outlet_state_unknown_string = 'unknown',
  auth_role_application_admin = 'application_admin',
  auth_role_account_admin = 'account_admin',
  auth_role_account_observer = 'account_observer',
  auth_role_machine = 'machine';

const constants = {
  NODE_ENV_LOCAL: "local",
  NODE_ENV_PRODUCTION: "production",
  CONTROLLER_POLL_INTERVAL: 3000,
  OUTLET_TYPE_ELECTRIC: "electric",
  OUTLET_TYPE_HYDRAULIC: "hydraulic",
  OUTLET_STATE_ON_STRING: outlet_state_on_string,
  OUTLET_STATE_OFF_STRING: outlet_state_off_string,
  OUTLET_STATE_UNKNOWN_STRING: outlet_state_unknown_string,
  CONTROL_SCHEME_TYPE_CONSTANT: control_scheme_type_constant,
  CONTROL_SCHEME_TYPE_SCHEDULED: control_scheme_type_scheduled,
  CONTROL_SCHEME_TYPE_PIANO_ROLL: control_scheme_type_piano_roll,
  CONTROL_SCHEME_TYPE_SENSOR: control_scheme_type_sensor,
  MS_PER_SEC: 1000,
  MS_PER_MIN: 60000,
  MS_PER_HOUR: 3600000,
  MS_PER_DAY: 86400000,
  LOCALSTORAGE_KEY_ACCESS_TOKEN: "accessToken",
  AUTH_ROLE_APPLICATION_ADMIN: auth_role_application_admin,
  AUTH_ROLE_ACCOUNT_ADMIN: auth_role_account_admin,
  AUTH_ROLE_ACCOUNT_OBSERVER: auth_role_account_observer,
  AUTH_ROLE_MACHINE: auth_role_machine,
  ALL_AUTH_ROLES: [auth_role_application_admin, auth_role_account_admin, auth_role_account_observer, auth_role_machine],
  CONTROLLER_HEARTBEAT_OK_LIMIT: 60000,
  NOTIFICATION_AUTOHIDE_DELAY: 2500,
};

export default constants;
