import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";


class TimeIntervalInput extends Component {

  static propTypes = {
    value: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: {days: 0, hours: 0, minutes: 0, seconds: 0},
    label: "",
    onChange: () => {},
  };

  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let {name: unitsOfTime, value} = event.target;
    const newIntervalValue = _.cloneDeep(this.props.value);
    newIntervalValue[unitsOfTime] = parseInt(value, 10);
    if (isNaN(newIntervalValue[unitsOfTime])) {
      newIntervalValue[unitsOfTime] = 0;
    }
    this.props.onChange(newIntervalValue);
  }

  render() {
    const {label, value} = this.props;

    return <div className="time-interval-input flex align-center justify-start sm-block">
      <span className="label font-norm mr20p text-right sm-block">{label}</span>
      <div className="field-group flex wrap">
        <div className="flex">
          <div className="interval-component flex flex-col mr15p">
            <span className="label font-norm mr20p w100 text-center italic">Days</span>
            <input type="text" name="days" value={value.days || ""}
                   onChange={this.handleChange}/>
          </div>
          <div className="interval-component flex flex-col mr15p">
            <span className="label font-norm mr20p w100 text-center italic">Hours</span>
            <input type="text" name="hours" value={value.hours || ""}
                   onChange={this.handleChange}/>
          </div>
        </div>
        <div className="flex">
          <div className="interval-component flex flex-col mr15p">
            <span className="label font-norm mr20p w100 text-center italic">Minutes</span>
            <input type="text" name="minutes" value={value.minutes || ""}
                   onChange={this.handleChange}/>
          </div>
          <div className="interval-component flex flex-col">
            <span className="label font-norm mr20p w100 text-center italic">Seconds</span>
            <input type="text" name="seconds" value={value.seconds || ""}
                   onChange={this.handleChange}/>
          </div>
        </div>
      </div>
    </div>;
  }

}

export default TimeIntervalInput;
