/**
 * Created by marcpeters on 8/20/17.
 */

import React, {Component} from "react";
import classnames from "classnames";
import EditableTextField from "../EditableTextField";
import { withServices } from "../../wrappers/ServicesWrapper";


class ControllerTitle extends Component {

  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
  }

  async onSave(newTitle) {
    const {controller, services: {automatorApiService, notificationService}} = this.props;
    try {
      await automatorApiService.patchController(controller.id, newTitle);
    } catch (err) {
      notificationService.error(err, "An error occurred editing the device name");
    }
  }

  render() {
    const {className, controller} = this.props;

    return (
      <div className={classnames("ml10p", className)}>
        <EditableTextField size="large" text={controller.friendlyName} onSave={this.onSave}/>
      </div>
    );
  }

}

export default withServices(ControllerTitle);
