import React, {Component} from "react";
import PropTypes from "prop-types";

import errorIcon from "../../images/error.svg";


class ErrorMessage extends Component {

  static propTypes = {
    message: PropTypes.string,
  };

  static defaultProps = {
    message: "",
  };

  render() {
    const {message} = this.props;

    return <div className="error-message">
      <img src={errorIcon} alt=""/>
      {message}
    </div>
  }
}

export default ErrorMessage;
