import * as _ from "lodash";
import constants from "../constants";
import {setUserPreferences, setSupportedTimezones} from "../redux/actions/user";
import {setControllers, setControllersLastSeen, setControlSchemes, setControllerFriendlyName, setStatus, clearStatus,
  setOutletFriendlyName, setOutletHistory, setAllOutletHistoryForController} from "../redux/actions/controllers";
import {setPrograms} from "../redux/actions/programs";
import {WebsocketClient} from 'websocket-transport';
import logger from "./Logger";
import assert from "browser-assert";
import { isUnauthorizedResponse, websocketNeedsReauthentication } from "../helpers/httpRequest";
import {toIntervalString} from "../helpers/schedule";


async function toJson(response) {
  const responseText = await response.text();
  return responseText && JSON.parse(responseText);
}


class AutomatorAPI {

  constructor({config, store}) {
    this._config = config;
    this._store = store;
    this._authToken = null;
    this._onRequestError = _.noop();

    this._websocketClient = new WebsocketClient({config, logger, host: config.API_HOST, port: config.API_PORT, useSsl: config.USE_SSL});
    this._websocketClient.onReconnect(async () => {
      try {
        await this.handleRequestError(async () => {
          // Reconnection event is fired right before connection event, so the websocket is not technically connected yet
          await this._websocketClient.connect();
          await this._websocketClient.authenticate(this._authToken);
          console.log("Reauthenticated");
        })();
      } catch (err) {
        console.log("Reauthentication error");
        console.log(err);
      }
    });

    this.connect = this.handleRequestError(this.connect.bind(this));
    this._httpRequest = this.handleRequestError(this._httpRequest.bind(this));
    this._wsRequest = this.handleRequestError(this._wsRequest.bind(this));

    this._pollControllerLastSeen = null;
  }


  onRequestError(cb) {
    this._onRequestError = cb;
  }


  handleRequestError(fn) {
    return async (...args) => {
      try {
        return await fn(...args);
      } catch (err) {
        this._onRequestError(err);
        throw err;
      }
    }
  }


  async connect(authToken) {
    if (!authToken) {
      console.warn("Can't connect - no auth token");
      return;
    }

    this._authToken = authToken;
    await this._websocketClient.connect();
    await this._websocketClient.authenticate(authToken);
  }


  async disconnect() {
    this._authToken = null;
    this._websocketClient.disconnect();
    this.cancelAllPollingTasks();
  }


  async _httpRequest() {
    assert(arguments[0], "path is required");
    const path = this._config.GC_API_URL + arguments[0];
    const fetchArgs = [path].concat(Array.from(arguments).slice(1));

    const response = await fetch.apply(null, fetchArgs);

    this._throwOnAuthenticationError(response);
    if (response.status < 200 || response.status >= 400) {
      throw new Error("HTTP error");
    }
    return toJson(response);
  }


  async _wsRequest(...args) {
    try {
      const response = await this._websocketClient.request(...args);
      return response;
    } catch (response) {
      if (websocketNeedsReauthentication(response)) {
        console.log("Reauthenticating...");
        await this._websocketClient.authenticate(this._authToken);
        return await this._websocketClient.request(...args);
      } else {
        throw response;
      }
    }
  }


  _throwOnAuthenticationError(response) {
    if (isUnauthorizedResponse(response)) {
      // Unauthorized
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }


  async getAccessToken(username, password) {
    const json = await this._httpRequest("/accesstoken", {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      }
    });

    return json.accessToken;
  }


  async fetchUserPreferences() {
    const {payload} = await this._wsRequest({
      method: "GET",
      path: "/users/me",
    });

    this._store.dispatch(setUserPreferences(payload));
  }


  async patchUser(timezone) {
    this._store.dispatch(setUserPreferences({timezone}));

    await this._wsRequest({
      method: "PATCH",
      path: "/users",
      payload: JSON.stringify({
        timezone,
      }),
    });
  }


  async fetchControllers() {
    const {payload} = await this._wsRequest({
      method: "GET",
      path: "/controllers",
    });

    this._store.dispatch(setControllers(payload));
  }


  async addController() {
    await this._wsRequest({
        method: "POST",
        path: "/users",
        payload: JSON.stringify({
          role: constants.AUTH_ROLE_MACHINE,
        }),
      });

    await this.fetchControllers();
  }


  async patchController(controllerId, friendlyName) {
    this._store.dispatch(setControllerFriendlyName(controllerId, friendlyName));

    await this._wsRequest({
        method: "PATCH",
        path: "/controllers/" + controllerId,
        payload: JSON.stringify({
          friendlyName,
        }),
      });

  }


  async deleteController(controllerId) {
    await this._wsRequest({
        method: "DELETE",
        path: `/controllers/${controllerId}`,
      });

    await this.fetchControllers();
  }


  pollControllerLastSeen() {
    if (this._pollControllerLastSeen) {
      return;
    }

    const pollingInterval = Math.max(Math.trunc(constants.CONTROLLER_HEARTBEAT_OK_LIMIT / 2), constants.MS_PER_SEC * 10);

    this._pollControllerLastSeen = setInterval(async () => {
      const {payload} = await this._wsRequest({
        method: "GET",
        path: `/controllers/last-seen`
      });

      this._store.dispatch(setControllersLastSeen(payload));
    }, pollingInterval)
  }


  cancelAllPollingTasks() {
    clearInterval(this._pollControllerLastSeen);
    this._pollControllerLastSeen = null;
  }


  async fetchOutletControlSchemes(controllerId) {
    const {payload} = await this._wsRequest({
      method: "GET",
      path: "/controllers/" + controllerId + "/outlets/controlschemes",
    });

    this._store.dispatch(setControlSchemes(controllerId, payload));
  }


  async getOutletHistory(controllerId, outletInternalId, isoStartDate, isoEndDate) {
    const {payload} = await this._wsRequest({
      method: "GET",
      path: `/controllers/${controllerId}/outlets/${outletInternalId}/history?startDate=${isoStartDate}&endDate=${isoEndDate}`,
    });

    this._store.dispatch(setOutletHistory(controllerId, outletInternalId, payload));
  }


  async getAllOutletHistoryForController(controllerId, outletInternalIds, isoStartDate, isoEndDate) {
    const asyncTasks = [];

    for (const outletInternalId of outletInternalIds) {
      asyncTasks.push(this._wsRequest({
        method: "GET",
        path: `/controllers/${controllerId}/outlets/${outletInternalId}/history?startDate=${isoStartDate}&endDate=${isoEndDate}`,
      }));
    }

    const responses = await Promise.all(asyncTasks);
    const allOutletHistory = [];

    outletInternalIds.forEach((outletInternalId, index) => {
      const history = responses[index].payload;
      allOutletHistory.push({outletInternalId, history});
    });

    this._store.dispatch(setAllOutletHistoryForController(controllerId, allOutletHistory));
  }


  async postControlScheme(controllerId, outletInternalId, controlSchemeData) {
    await this._wsRequest({
        method: "POST",
        path: "/controllers/" + controllerId + "/outlets/" + outletInternalId + "/controlscheme",
        payload: JSON.stringify(controlSchemeData),
      });

    await this.fetchOutletControlSchemes(controllerId);
  }

  async getControllerStatus(controllerId) {
    this._store.dispatch(clearStatus(controllerId));

    const {payload} = await this._wsRequest({
      method: "GET",
      path: `/controllers/${controllerId}/status`,
    });

    this._store.dispatch(setStatus(controllerId, payload));
  }

  async patchOutlet(controllerId, outletInternalId, friendlyName) {
    await this._wsRequest({
      method: "PATCH",
      path: `/controllers/${controllerId}/outlets/${outletInternalId}`,
      payload: JSON.stringify({
        friendlyName,
      }),
    });

    this._store.dispatch(setOutletFriendlyName(controllerId, outletInternalId, friendlyName));
  }

  async fetchPrograms() {
    const {payload} = await this._wsRequest({
      method: "GET",
      path: "/programs",
    });

    this._store.dispatch(setPrograms(payload));
  }

  async addProgram() {
    await this._wsRequest({
      method: "POST",
      path: "/programs",
      payload: {
        friendlyName: "New program",
        instructions: []
      },
    });

    await this.fetchPrograms();
  }

  async patchProgram(programId, friendlyName, _instructions) {
    const instructions = _instructions.map((instruction) => {
      return {
        ...instruction,
        duration: toIntervalString(instruction.duration)
      };
    });

    await this._wsRequest({
      method: "PATCH",
      path: `/programs/${programId}`,
      payload: {
        friendlyName,
        instructions
      },
    });

    await this.fetchPrograms();
  }

  async deleteProgram(programId) {
    await this._wsRequest({
      method: "DELETE",
      path: `/programs/${programId}`,
    });

    await this.fetchPrograms();
  }

  async fetchSupportedTimezones() {
    const {payload} = await this._wsRequest({
      method: "GET",
      path: "/timezones",
    });

    this._store.dispatch(setSupportedTimezones(payload.timezones));
  }
}

export default AutomatorAPI;
