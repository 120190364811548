import * as _ from "lodash";
import {SET_CONTROLLERS, SET_CONTROLLER_FRIENDLY_NAME, SET_CONTROLLERS_LAST_SEEN, SET_CONTROL_SCHEMES, SET_STATUS, CLEAR_STATUS,
  SET_OUTLET_FRIENDLY_NAME, CLEAR_OUTLET_HISTORY, SET_OUTLET_HISTORY, SET_ALL_OUTLET_HISTORY_FOR_CONTROLLER} from "../actions/controllers";

const initialState = {
  controllers: null,
  controlSchemes: null,
  outletHistory: [],
  status: null,
};

const getLocalState = state => state.controllerStore;

export default function controllers(state = initialState, action) {
  const nextState = _.cloneDeep(state);

  switch (action.type) {
    case SET_CONTROLLERS:
      nextState.controllers = action.controllers;
      return nextState;
    case SET_CONTROLLER_FRIENDLY_NAME:
      (nextState.controllers || []).forEach((controller) => {
        if (controller.id === action.controllerId) {
          controller.friendlyName = action.friendlyName;
        }
      });
      return nextState;
    case SET_CONTROLLERS_LAST_SEEN:
      const controllerIdsFromAction = Object.keys(action.controllers);
      (nextState.controllers || []).forEach((controller) => {
        if (controllerIdsFromAction.includes(controller.id.toString())) {
          controller.lastSeenAt = action.controllers[controller.id].lastSeenAt;
        }
      });
      return nextState;
    case SET_CONTROL_SCHEMES:
      nextState.controlSchemes = (nextState.controlSchemes || {});
      nextState.controlSchemes[action.controllerId] = _.mapValues(action.controlSchemes, (controlScheme) => {
        return _.defaultsDeep(_.cloneDeep(controlScheme), {
          duration: {
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0
          },
          frequency: {
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0
          },
        })
      });
      return nextState;
    case SET_STATUS:
      nextState.status = (nextState.status || {});
      nextState.status[action.controllerId] = action.status;
      return nextState;
    case CLEAR_STATUS:
      nextState.status = (nextState.status || {});
      nextState.status[action.controllerId] = null;
      return nextState;
    case SET_OUTLET_FRIENDLY_NAME:
      const controller = (nextState.controllers || []).find(_.matches({id: action.controllerId}));

      if (!controller) {
        throw new Error(`Controller ${action.controllerId} not found in store`);
      }

      const outlet = controller.outlets.find(_.matches({internalId: action.outletInternalId}));

      if (!outlet) {
        throw new Error(`Outlet with internal ID ${action.outletInternalId} for controller ${action.controllerId} 
          not found in store`);
      }

      outlet.friendlyName = action.friendlyName;
      return nextState;
    case CLEAR_OUTLET_HISTORY:
      return (() => {
        const {controllerId, outletInternalId} = action;
        const clearedOutletHistory = nextState.outletHistory.filter(_.negate(_.matches({controllerId, outletInternalId})));
        nextState.outletHistory = clearedOutletHistory;
        return nextState;
      })();
    case SET_OUTLET_HISTORY:
      return (() => {
        const timeStamp = new Date().getTime();
        const {controllerId, outletInternalId, history} = action;
        const clearedOutletHistory = nextState.outletHistory.filter(_.negate(_.matches({controllerId, outletInternalId})));
        clearedOutletHistory.push({controllerId, outletInternalId, history, timeStamp});
        nextState.outletHistory = _.sortBy(clearedOutletHistory, "controllerId", "outletInternalId");
        return nextState;
      })();
    case SET_ALL_OUTLET_HISTORY_FOR_CONTROLLER:
      return (() => {
        const timeStamp = new Date().getTime();
        const {controllerId, allOutletHistory} = action;
        const clearedOutletHistory = nextState.outletHistory.filter(_.negate(_.matches({controllerId})));

        for (const outletData of allOutletHistory) {
          clearedOutletHistory.push({controllerId, outletInternalId: outletData.outletInternalId, history: outletData.history, timeStamp});
        }
        nextState.outletHistory = _.sortBy(clearedOutletHistory, "controllerId", "outletInternalId");
        return nextState;
      })();

    default:
      return state;
  }
}

export function getController(state, controllerId) {
  return getLocalState(state).controllers &&
    getLocalState(state).controllers.find(controller => controller.id === controllerId);
}

export function getOutlet(state, controllerId, outletInternalId) {
  const controller = (getLocalState(state).controllers || []).find(_.matches({id: controllerId}));
  return controller && controller.outlets.find(_.matches({internalId: outletInternalId}));
}

export function getControlSchemes(state, controllerId) {
  return getLocalState(state).controlSchemes &&
    getLocalState(state).controlSchemes[controllerId];
}

export function getStatus(state, controllerId) {
  return getLocalState(state).status &&
    getLocalState(state).status[controllerId];
}

export function getOutletHistoryForController(state, controllerId) {
  return getLocalState(state).outletHistory.filter(_.matches({controllerId})).map((historyForOutlet) => {
    return {
      outletInternalId: historyForOutlet.outletInternalId,
      history: historyForOutlet.history,
      timeStamp: historyForOutlet.timeStamp,
    };
  });
}

export function getOutletHistoryForOutlet(state, controllerId, outletInternalId) {
  return getLocalState(state).outletHistory.find(_.matches({controllerId, outletInternalId})).history;
}
