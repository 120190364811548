import React from 'react';
import { connect } from 'react-redux'
import { getIsAuthenticated, getIsInitialized } from "../../redux/reducers/authentication";
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, isAuthenticated, isInitialized, ...rest}) => {
  return <Route {...rest} render={(props) => (
    !isInitialized ? null :
    isAuthenticated ?
      <Component {...props}/>
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
  )}/>
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: getIsAuthenticated(state),
  isInitialized: getIsInitialized(state),
});

export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(PrivateRoute);
