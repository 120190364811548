import React, {useState} from "react";
import PropTypes from "prop-types";
import {withServices} from "../../wrappers/ServicesWrapper";
import Icon from "../Icon";
import calendar from "../../images/calendar.svg";
import Calendar from "react-calendar";
import ClickOutsideNotifier from "../ClickOutsideNotifier";
import NumericInput from "../NumericInput";


function DateTimePicker(props) {
  const selectedDate = props.value;
  const [datePickerIsShowing, setDatePickerIsShowing] = useState(false);

  const datePicker = <div key= {1} className="fixed z400 flex align-center justify-center"
                        style={{top: "0px", bottom: "0px", left: "0px", right: "0px", background: "rgba(0,0,0,0.5)",
                                backdropFilter: "blur(4px)"}}>
    <ClickOutsideNotifier onClickOutside={() => setDatePickerIsShowing(false)}>
      <Calendar className="bg-white" onChange={(date) => {
        setDatePickerIsShowing(false);
        props.onChange({...selectedDate, year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()});
      }}/>
    </ClickOutsideNotifier>
  </div>;

  return <div>
    <div key={0} className="flex wrap mt-10p">
      <div className="flex no-shrink align-center h44p mt10p mr15p font-20p brd-1 brd-rad-4 brd-gray-80 curs-pointer"
           onClick={() => setDatePickerIsShowing(!datePickerIsShowing)}>
        <div className="ml17p">{`${selectedDate.year}-${selectedDate.month.toString().padStart(2, "0")}-${selectedDate.day.toString().padStart(2, "0")}`}</div>
        <Icon className="ml15p mr10p" src={calendar} alt="calendar" style={{height: "19px", marginTop: "-3px"}}/>
      </div>
      <div className="flex align-center justify-start mt10p">
        <NumericInput className="w70p" label="HOUR" min={0} max={23} digits={2} value={selectedDate.hour}
          onChange={(hour) => {
            props.onChange({...selectedDate, hour});
          }}/>
        <span className="mx5p font-sz3">:</span>
        <NumericInput className="w70p" label="MIN" min={0} max={59} digits={2} value={selectedDate.minute}
          onChange={(minute) => {
            props.onChange({...selectedDate, minute});
          }}/>
        <span className="mx5p font-sz3">:</span>
        <NumericInput className="w70p" label="SEC" min={0} max={59} digits={2} value={selectedDate.second}
          onChange={(second) => {
            props.onChange({...selectedDate, second});
          }}/>
        <span className="mx5p font-sz3">.</span>
        <NumericInput className="w80p" label="MSEC" min={0} max={999} digits={3} value={selectedDate.millisecond}
          onChange={(millisecond) => {
            props.onChange({...selectedDate, millisecond});
          }}/>
      </div>
    </div>
    {datePickerIsShowing ? datePicker : null}
  </div>
}


DateTimePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object
};

DateTimePicker.defaultProps = {
  onChange: () => {},
  value: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()}
};


export default withServices(DateTimePicker);