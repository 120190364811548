import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import computerChipIcon from "../../images/computer-chip.svg";
import computerChipIconGreen from "../../images/computer-chip-green.svg";
import warningIcon from "../../images/high-voltage.svg";
import constants from "../../constants";

const CONTROLLER_DATA_LOADING = "loading",
  CONTROLLER_OFFLINE = "offline",
  CONTROLLER_ONLINE = "online";


class ControllerStatusIndicator extends Component {

  static propTypes = {
    controllerId: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.getBasicControllerStatus = this.getBasicControllerStatus.bind(this);
    this.state = {
      status: this.getBasicControllerStatus(props.controller)
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      const currentStatus = this.getBasicControllerStatus(this.props.controller);
      if (currentStatus !== this.state.status) {
        this.setState({
          status: this.getBasicControllerStatus(this.props.controller)
        });
      }
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getBasicControllerStatus(controller) {
    if (!controller) {
      return CONTROLLER_DATA_LOADING;
    } else if ((new Date().getTime() - new Date(controller.lastSeenAt).getTime()) > constants.CONTROLLER_HEARTBEAT_OK_LIMIT) {
      return CONTROLLER_OFFLINE;
    }
    return CONTROLLER_ONLINE;
  }

  render() {
    let images = null;

    switch(this.state.status) {
      case CONTROLLER_DATA_LOADING:
        images = [
          <img key="1" className={classNames("loading")} src={computerChipIcon} alt="getting device status"/>,
          <div key="2" className={classNames("overlay", "loading")}></div>
        ];
        break;
      case CONTROLLER_ONLINE:
        images = <img src={computerChipIconGreen} alt="device online"/>;
        break;
      case CONTROLLER_OFFLINE:
        images = [
          <img key="1" className={classNames("failed")} src={warningIcon} alt="device unreachable"/>
        ];
        break;
      default:
        throw new Error("Unknown controller status");
    }

    return <div className={classNames("status-indicator")}>
      {images}
    </div>;
  }
}

export default ControllerStatusIndicator;
