import { connect } from 'react-redux'
import { getController } from "../../redux/reducers/controllers";
import ControllerStatusIndicator from './ControllerStatusIndicator';

const mapStateToProps = (state, ownProps) => ({
  controller: getController(state, parseInt(ownProps.controllerId, 10)),
});

export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(ControllerStatusIndicator);