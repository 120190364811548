import * as _ from "lodash";
import React, {Component} from "react";
import classNames from "classnames";
import Button from '../Button'

class SecretText extends Component {

  constructor() {
    super();
    this.state = { hidden: true };

    this.toggleHidden = this.toggleHidden.bind(this);
  }

  toggleHidden() {
    const newState = _.cloneDeep(this.state);
    newState.hidden = !Boolean(newState.hidden);

    this.setState(newState);
  }

  render() {
    const {text, className, ...rest} = this.props;
    let body = null;

    if (this.state.hidden) {
      body = <Button colour="grey" title="Reveal" onClick={this.toggleHidden}/>;
    }
    else {
      body = <div>
        <span className="font-norm" style={{width: "100%", wordWrap: "break-word", display: "inline-block"}} {...rest}>{text}</span>
        <br/>
        <Button colour="grey" title="Hide" className="mt15p" onClick={this.toggleHidden}/>
      </div>;
    }

    return <div className={classNames("SecretText", className)}>
      {body}
    </div>
  }
}

export default SecretText;
