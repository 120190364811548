import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { getIsAuthenticated } from '../../redux/reducers/authentication';
import userIcon from '../../images/user.svg';

const UserNavItem = ({isAuthenticated}) => {
  return isAuthenticated ?
      <Link id="user-nav-item" to="/preferences"><img src={userIcon} className="icon" alt="My preferences"/></Link> :
    null;
};

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
});

export default connect(
  mapStateToProps,
)(UserNavItem);
