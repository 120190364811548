import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getIsSideNavShowing } from '../../redux/reducers/userInterface';
import { toggleSideNavShowing } from '../../redux/actions/userInterface';


class Overlay extends Component {

  render() {
    const {isSideNavShowing, toggleSideNavShowing} = this.props;
    return <div id="overlay" onClick={toggleSideNavShowing} className={classNames("z200", {"overlay-hidden": !isSideNavShowing})}>
    </div>
  }

}

const mapStateToProps = (state) => ({
  isSideNavShowing: getIsSideNavShowing(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleSideNavShowing: () => dispatch(toggleSideNavShowing()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overlay);
