/*
 * action types
 */

export const SET_CONTROLLERS = 'SET_CONTROLLERS';
export const SET_CONTROLLER_FRIENDLY_NAME = 'SET_CONTROLLER_FRIENDLY_NAME';
export const SET_CONTROLLERS_LAST_SEEN = 'SET_CONTROLLERS_LAST_SEEN';
export const SET_CONTROL_SCHEMES = 'SET_CONTROL_SCHEMES';
export const SET_STATUS = 'SET_STATUS';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const SET_OUTLET_FRIENDLY_NAME = 'SET_OUTLET_FRIENDLY_NAME';
export const CLEAR_OUTLET_HISTORY = 'CLEAR_OUTLET_HISTORY';
export const SET_OUTLET_HISTORY = 'SET_OUTLET_HISTORY';
export const SET_ALL_OUTLET_HISTORY_FOR_CONTROLLER = 'SET_ALL_OUTLET_HISTORY_FOR_CONTROLLER';


/*
 * action creators
 */

export function setControllers(controllers) {
  return { type: SET_CONTROLLERS, controllers };
}

export function setControllerFriendlyName(controllerId, friendlyName) {
  return { type: SET_CONTROLLER_FRIENDLY_NAME, controllerId, friendlyName };
}

export function setControllersLastSeen(controllers) {
  return { type: SET_CONTROLLERS_LAST_SEEN, controllers };
}

export function setControlSchemes(controllerId, controlSchemes) {
  return { type: SET_CONTROL_SCHEMES, controllerId, controlSchemes };
}

export function setStatus(controllerId, status) {
  return { type: SET_STATUS, controllerId, status };
}

export function clearStatus(controllerId) {
  return { type: CLEAR_STATUS, controllerId};
}

export function setOutletFriendlyName(controllerId, outletInternalId, friendlyName) {
  return { type: SET_OUTLET_FRIENDLY_NAME, controllerId, outletInternalId, friendlyName };
}

export function clearOutletHistory(controllerId, outletInternalId) {
  return { type: CLEAR_OUTLET_HISTORY, controllerId, outletInternalId };
}

export function setOutletHistory(controllerId, outletInternalId, history) {
  return { type: SET_OUTLET_HISTORY, controllerId, outletInternalId, history };
}

export function setAllOutletHistoryForController(controllerId, allOutletHistory) {
  return { type: SET_ALL_OUTLET_HISTORY_FOR_CONTROLLER, controllerId, allOutletHistory };
}
