import {setIsAuthenticated, setIsInitialized} from "../redux/actions/authentication";
import constants from '../constants'
import { isUnauthorizedResponse } from "../helpers/httpRequest";


class AuthenticationService {

  constructor({automatorApiService, notificationService, store}) {
    this._automatorApiService = automatorApiService;
    this._notificationService = notificationService;
    this._store = store;
  }

  async initialize() {
    await this._authenticate(localStorage.getItem(constants.LOCALSTORAGE_KEY_ACCESS_TOKEN));
    this._store.dispatch(setIsInitialized(true));
  }

  async _authenticate(accessToken) {
    if (!accessToken) return;

    try {
      await this._automatorApiService.connect(accessToken);
    } catch (err) {
      if (isUnauthorizedResponse(err)) {
        this._notificationService.error(err, "You have been logged out");
        return this.logout();
      }
      return this._notificationService.error(err, "Could not connect to the server");
    }

    try {
      await this._automatorApiService.fetchControllers();
      this._automatorApiService.pollControllerLastSeen();
    } catch (err) {
      return this._notificationService.error(err, "Could not connect to the server");
    }

    this._accessToken = accessToken;
    localStorage.setItem(constants.LOCALSTORAGE_KEY_ACCESS_TOKEN, accessToken);
    this._store.dispatch(setIsAuthenticated(true));
  }

  async login(username, password) {
    try {
      const accessToken = await this._automatorApiService.getAccessToken(username, password);
      await this._authenticate(accessToken);
    } catch (err) {
      this._notificationService.error(err, "An error occurred when logging in");
    }
  }

  get accessToken() {
    return this._accessToken;
  }

  async logout() {
    this._accessToken = null;
    localStorage.removeItem(constants.LOCALSTORAGE_KEY_ACCESS_TOKEN);
    await this._automatorApiService.disconnect();
    this._store.dispatch(setIsAuthenticated(false));
  }
}

export default AuthenticationService;
