/*
 * action types
 */

export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
export const SET_SUPPORTED_TIMEZONES = 'SET_SUPPORTED_TIMEZONES';
export const SET_FORM_VALUE = 'SET_FORM_VALUE';
export const RESET_FORM = 'RESET_FORM';


/*
 * action creators
 */

export function setUserPreferences(preferences) {
  return { type: SET_USER_PREFERENCES, preferences };
}

export function setSupportedTimezones(supportedTimezones) {
  return { type: SET_SUPPORTED_TIMEZONES, supportedTimezones };
}

export function setFormValue(element, value) {
  return { type: SET_FORM_VALUE, element, value };
}

export function resetForm() {
  return { type: RESET_FORM }
}
