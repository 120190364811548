/**
 * Created by marcpeters on 8/20/17.
 */

import React, {Component} from "react";
import classNames from "classnames";

import "./style.css";
import Button from "../Button";

export default class NavButton extends Component {

  render() {
    const {className, title, onClick} = this.props;

    return (
      <Button onClick={onClick} colour="blue" title={title} className={classNames("nav-button", className)}/>
    );
  }

}
