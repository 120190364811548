import * as _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "../Button";
import constants from "../../constants";
import {toIntervalString, toMillis} from "../../helpers/schedule";

import ErrorMessage from "../ErrorMessage";
import { withServices } from "../../wrappers/ServicesWrapper";
import { Link } from "react-router-dom";
import ConstantModeForm from "./ConstantModeForm";
import ScheduledModeForm from "./ScheduledModeForm";
import ControlSchemeDropdown from "./ControlSchemeDropdown";
import OutletHeader from "./OutletHeader";
import ProgrammedModeForm from "./ProgrammedModeForm";

class OutletDetail extends Component {

  static propTypes = {
    controllerId: PropTypes.string, //TODO: Should arrive as number.  May need backend change.
    controlScheme: PropTypes.object,
    outlet: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.getPristineState = this.getPristineState.bind(this);
    this.isFormDirty = this.isFormDirty.bind(this);
    this.saveControlSchemeChanges = this.saveControlSchemeChanges.bind(this);
    this.cancelControlSchemeChanges = this.cancelControlSchemeChanges.bind(this);
    this.handleControlSchemeChanged = this.handleControlSchemeChanged.bind(this);
    this.handleScheduleStartTimeChanged = this.handleScheduleStartTimeChanged.bind(this);
    this.handleScheduleEndTimeChanged = this.handleScheduleEndTimeChanged.bind(this);
    this.handleScheduleDurationChanged = this.handleScheduleDurationChanged.bind(this);
    this.handleScheduleFrequencyChanged = this.handleScheduleFrequencyChanged.bind(this);
    this.handleProgramIdChanged = this.handleProgramIdChanged.bind(this);
    this.handleProgramStartTimeChanged = this.handleProgramStartTimeChanged.bind(this);
    this.handleProgramEndTimeChanged = this.handleProgramEndTimeChanged.bind(this);
    this.handleOnOffChanged = this.handleOnOffChanged.bind(this);
    this.calculateErrorMessage = this.calculateErrorMessage.bind(this);
    this.instantiationTime = new Date().getTime();

    this.state = {
      collapsed: true,
      ...this.getPristineState(props)
    };
  }

  toggleExpanded() {
    this.setState({collapsed: !this.state.collapsed});
  }

  getPristineState(props) {
    const {controlScheme} = props;
    const defaultTimeInterval = {days: 0, hours: 0, minutes: 0, seconds: 0};

    return {
      mode: controlScheme.scheme,
      constantValue: controlScheme.outletState || constants.OUTLET_STATE_OFF_STRING,
      scheduledValue: controlScheme.scheme === constants.CONTROL_SCHEME_TYPE_SCHEDULED ?
        _.pick(controlScheme, ["startOn", "endOn", "duration", "frequency"]) :
        {
          startOn: new Date(this.instantiationTime + 5 * constants.MS_PER_MIN).toISOString(),
          endOn: new Date(this.instantiationTime + 15 * constants.MS_PER_MIN).toISOString(),
          duration: Object.assign({}, defaultTimeInterval),
          frequency: Object.assign({}, defaultTimeInterval),
        },
      programmedValue: controlScheme.scheme === constants.CONTROL_SCHEME_TYPE_PIANO_ROLL ?
        _.pick(controlScheme, ["programId", "startOn", "endOn"]) :
        {
          programId: null,
          startOn: {year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate(),
            hour: 0, minute: 0, second: 0, millisecond: 0},
          endOn: {year: new Date().getFullYear() + 1, month: new Date().getMonth() + 1, day: new Date().getDate(),
            hour: 0, minute: 0, second: 0, millisecond: 0}
        },
    };
  }

  isFormDirty() {
    const pristineState = this.getPristineState(this.props);

    if (this.state.mode !== pristineState.mode) {
      return true;
    }
    else if (this.state.mode === constants.CONTROL_SCHEME_TYPE_CONSTANT) {
      return this.state.constantValue !== pristineState.constantValue;
    }
    else if (this.state.mode === constants.CONTROL_SCHEME_TYPE_SCHEDULED) {
      const scheduleModeProperties = ["startOn", "endOn", "frequency", "duration"];
      return !_.isEqual(_.pick(this.state.scheduledValue, scheduleModeProperties), _.pick(pristineState.scheduledValue,
        scheduleModeProperties));
    }
    else if (this.state.mode === constants.CONTROL_SCHEME_TYPE_PIANO_ROLL) {
      const programmedModeProperties = ["programId", "startOn", "endOn"];
      return !_.isEqual(_.pick(this.state.programmedValue, programmedModeProperties),
        _.pick(pristineState.programmedValue, programmedModeProperties));
    }
    else {
      return false;
    }
  }

  handleControlSchemeChanged(schemeName) {
    if (schemeName === this.state.mode) return;

    const pristineState = this.getPristineState(this.props);

    const newState = Object.assign({}, pristineState, {
      mode: schemeName,
    });

    this.setState(newState);
  }

  handleOnOffChanged(isOn) {
    this.setState({
      constantValue: isOn ? constants.OUTLET_STATE_ON_STRING : constants.OUTLET_STATE_OFF_STRING,
    });
  }

  handleScheduleStartTimeChanged(event) {
    let {value} = event.target;
    const newState = _.cloneDeep(this.state);
    newState.scheduledValue.startOn = value;
    this.setState(newState);
  }

  handleScheduleEndTimeChanged(event) {
    let {value} = event.target;
    const newState = _.cloneDeep(this.state);
    newState.scheduledValue.endOn = value;
    this.setState(newState);
  }

  handleScheduleDurationChanged(timeInterval) {
    const newState = _.cloneDeep(this.state);
    newState.scheduledValue.duration = timeInterval;
    this.setState(newState);
  }

  handleScheduleFrequencyChanged(timeInterval) {
    const newState = _.cloneDeep(this.state);
    newState.scheduledValue.frequency = timeInterval;
    this.setState(newState);
  }

  handleProgramIdChanged(programId) {
    const newState = _.cloneDeep(this.state);
    newState.programmedValue.programId = programId;
    this.setState(newState);
  }

  handleProgramStartTimeChanged(date) {
    const newState = _.cloneDeep(this.state);
    newState.programmedValue.startOn = date;
    this.setState(newState);
  }

  handleProgramEndTimeChanged(date) {
    const newState = _.cloneDeep(this.state);
    newState.programmedValue.endOn = date;
    this.setState(newState);
  }

  async saveControlSchemeChanges(outletInternalId) {
    const { controllerId, services: {automatorApiService, notificationService} } = this.props;
    const { mode, constantValue, scheduledValue, programmedValue } = this.state;

    try {
      switch(mode) {
        case constants.CONTROL_SCHEME_TYPE_CONSTANT:
          await automatorApiService.postControlScheme(controllerId, outletInternalId, {
            scheme: constants.CONTROL_SCHEME_TYPE_CONSTANT,
            outletState: constantValue
          });
          break;
        case constants.CONTROL_SCHEME_TYPE_SCHEDULED:
          await automatorApiService.postControlScheme(controllerId, outletInternalId, {
            scheme: constants.CONTROL_SCHEME_TYPE_SCHEDULED,
            startOn: scheduledValue.startOn.replace("Z", "+00:00"),
            endOn: scheduledValue.endOn.replace("Z", "+00:00"),
            duration: toIntervalString(scheduledValue.duration),
            frequency: toIntervalString(scheduledValue.frequency)
          });
          break;
        case constants.CONTROL_SCHEME_TYPE_PIANO_ROLL:
          await automatorApiService.postControlScheme(controllerId, outletInternalId, {
            scheme: constants.CONTROL_SCHEME_TYPE_PIANO_ROLL,
            programId: programmedValue.programId,
            startOn: programmedValue.startOn,
            endOn: programmedValue.endOn
          });
          break;
        default:
          throw new Error(`Attempted to save unknown control scheme type ${mode}`);
      }
    } catch (err) {
      notificationService.error(err, "An error occurred saving the outlet settings");
    }
  }

  cancelControlSchemeChanges() {
    this.setState(this.getPristineState(this.props));
  }

  calculateErrorMessage() {
    const {scheduledValue, programmedValue} = this.state;
    let errorMessage = "";

    switch(this.state.mode) {
      case constants.CONTROL_SCHEME_TYPE_CONSTANT:
        break;
      case constants.CONTROL_SCHEME_TYPE_SCHEDULED:
        if (toMillis(scheduledValue.duration) < 1) {
          errorMessage = "You must specify an ON time";
        } else if (toMillis(scheduledValue.frequency) < 1) {
          errorMessage = "You must specify how often the schedule should repeat";
        } else if (toMillis(scheduledValue.duration) > toMillis(scheduledValue.frequency)) {
          errorMessage = "ON time cannot be greater than the repeat frequency";
        }
        break;
      case constants.CONTROL_SCHEME_TYPE_PIANO_ROLL:
        if (programmedValue.programId === null) {
          errorMessage = "You must select a program"
        }
        break;
      default:
        throw new Error("Unknown control scheme");
    }

    return errorMessage;
  }

  render() {
    const {outlet, controllerId, className} = this.props;
    const {mode, constantValue, scheduledValue, programmedValue} = this.state;
    const expanded = !this.state.collapsed;
    const collapsed = this.state.collapsed;
    const formDirty = this.isFormDirty();

    const errorMessage = this.calculateErrorMessage();
    const editingControls = [
      <Button key={0} colour="green" title="Save" disabled={Boolean(errorMessage)}
              onClick={() => this.saveControlSchemeChanges(outlet.internalId)}/>,
      <Button key={1} colour="red" title="Cancel" className="ml15p"
              onClick={() => this.cancelControlSchemeChanges(outlet.internalId)}/>
    ];

    let controlSchemeEditingForm = null;

    switch (mode) {
      case constants.CONTROL_SCHEME_TYPE_CONSTANT:
        controlSchemeEditingForm = <ConstantModeForm value={constantValue} onClick={this.handleOnOffChanged}/>;
        break;
      case constants.CONTROL_SCHEME_TYPE_SCHEDULED:
        controlSchemeEditingForm = <ScheduledModeForm
          startOn={scheduledValue.startOn} endOn={scheduledValue.endOn}
          onStartTimeChanged={this.handleScheduleStartTimeChanged} onEndTimeChanged={this.handleScheduleEndTimeChanged}
          frequency={scheduledValue.frequency} duration={scheduledValue.duration}
          onDurationChanged={this.handleScheduleDurationChanged} onFrequencyChanged={this.handleScheduleFrequencyChanged}
        />;
        break;
      case constants.CONTROL_SCHEME_TYPE_PIANO_ROLL:
        controlSchemeEditingForm = <ProgrammedModeForm
          startOn={programmedValue.startOn} endOn={programmedValue.endOn} programId={programmedValue.programId}
          onStartTimeChanged={this.handleProgramStartTimeChanged} onEndTimeChanged={this.handleProgramEndTimeChanged}
          onProgramIdChanged={this.handleProgramIdChanged}
          />;
        break;
      default:
        throw new Error(`unknown control scheme ${mode}`);
    }

    return <div className={classNames("outlet-detail", {expanded}, {collapsed}, className)}>
      <OutletHeader expanded={expanded} outlet={outlet} onClick={this.toggleExpanded}/>
      {collapsed ? null :
      <div className="p10">
        <div className="mb5p flex align-center justify-start sm-wrap">
          <span className="mr20p mb5p font-norm italic no-shrink">Mode:</span>
          <ControlSchemeDropdown selectedValue={mode} onSelectedOptionChanged={this.handleControlSchemeChanged}/>
        </div>
        {controlSchemeEditingForm}
        {errorMessage ? <ErrorMessage message={errorMessage}/> : null}
        <div className="flex justify wrap mt30p mx5p">
          <div className="flex">
            {formDirty ? editingControls : null}
          </div>
          <Link to={`/controllers/${controllerId}/outlets/${outlet.internalId}`} className={classNames({"hide": formDirty})}>
            <Button colour="grey" title="Settings"/>
          </Link>
        </div>
      </div>}
    </div>;
  }

}

export default withServices(OutletDetail);
