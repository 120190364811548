/**
 * Created by marcpeters on 8/16/17.
 */

import React, { Component } from 'react';
import classnames from 'classnames';

import './style.css';

export default class NotFound extends Component {

  render() {
    const { className } = this.props;
    return (
      <div className={classnames('NotFound', className)}>
        <h1>
          <em>404 <small>Not Found</small></em>
        </h1>
      </div>
    );
  }

}
