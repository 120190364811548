import React, {Component} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";

export default class ProgramStub extends Component {

  render() {
    const { className, program } = this.props;

    return (
      <Link to={"/programs/" + program.id} className={classNames("program-stub", "trans-0-1", className)}>
        <span className={classNames("name ml5p", {unnamed: !program.friendlyName})}>{program.friendlyName || "Unnamed"}</span>
      </Link>
    );
  }

}

