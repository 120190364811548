import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash"
import classNames from "classnames";
import arrowIcon from "../../images/arrow.svg";
import ClickOutsideNotifier from "../ClickOutsideNotifier";

class DropDownList extends Component {

  static propTypes = {
    prompt: PropTypes.string,
    options: PropTypes.array,
    selectedIndex: PropTypes.number,
    selectedValue: PropTypes.any,
    onSelectedOptionChanged: PropTypes.func,
  };

  static defaultProps = {
    prompt: "Select an option",
    options: [],
    selectedIndex: null,
    selectedValue: null,
    onSelectedOptionChanged: () => void 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };

    this.onSelectedOptionChanged = this.onSelectedOptionChanged.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  onSelectedOptionChanged(value) {
    this.props.onSelectedOptionChanged(value);
    this.toggleExpanded();
  }

  render() {
    const {options, selectedIndex, selectedValue, prompt, className} = this.props;
    const {expanded} = this.state;

    // TODO: Remove selectedIndex prop once nobody else is calling this component with a selectedIndex
    const _selectedIndex = selectedIndex === null ? options.findIndex(({value}) => value === selectedValue)
      : selectedIndex;

    const list = options.map((option, index) => {
      return <div className={classNames("curs-pointer", "bg-gray-90-hov", "p5", "pr40")}
                  key={index}
                  onClick={() => this.onSelectedOptionChanged(option.value)}>
        {option.display}
      </div>
    });

    const someOptionIsSelected = !!options[_selectedIndex];
    const displayedText = _.get(options[_selectedIndex], "display", prompt);

    return <div className={classNames("relative", "grid", className)}>
      <div className="dd-wrapper brd-1 bg-white inline-block">
        <div className="flex p5 curs-pointer" onClick={this.toggleExpanded}>
          <div className={classNames({"clr-gray-40 italic": !someOptionIsSelected})}>{displayedText}</div>
          <div className="ml15p flex grow justify-end">
            <img src={arrowIcon} className={classNames("h20p", {"rotate-90": expanded})} alt={!expanded ? "Expand" : "Collapse"}/>
          </div>
        </div>
        <div className="ovf-hidden ovf-y-scroll h0p">{list}</div>
      </div>

      {expanded ?
      <ClickOutsideNotifier onClickOutside={this.toggleExpanded}>
        <div className="dd-wrapper t0 brd-1 bg-white bx-shdw-lg-soft-0 absolute z100">
          <div className="flex p5 curs-pointer brd-bot-1 brd-gray-75" onClick={this.toggleExpanded}>
            <div className="clr-gray-65">{displayedText}</div>
            <div className="ml15p flex grow justify-end">
              <img src={arrowIcon} className={classNames("h20p", {"rotate-90": expanded})}
                   alt={!expanded ? "Expand" : "Collapse"}/>
            </div>
          </div>
          <div className="ovf-y-scroll" style={{maxHeight: "250px"}}>
          {list}
          </div>
        </div>
      </ClickOutsideNotifier>
      : null}
    </div>
  }
}

export default DropDownList;
