/**
 * Created by marcpeters on 8/20/17.
 */

import React, {Component} from "react";
import classnames from "classnames";

import "./style.css";

export default class Icon extends Component {

  render() {
    const {className, alt, ...props} = this.props;

    return (
      <div className={classnames("Icon", className)}>
        <span className="verticalAlignHelper"></span>
        <img className="Icon-image" alt={alt || ""} {...props}/>
      </div>
    );
  }

}
